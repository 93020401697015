import React from "react";
import "./styles.css";
import { Layout } from "../../components/Layout";

export const CanalesAtencion = () => {
  const handleChatBox = () => {
    let chatbox = document.querySelector(`[chat-title="ChatbotMerkademia"]`);
    chatbox.setAttribute("expand", "");
    console.log(chatbox);
  };
  return (
    <Layout>
      <div className="contact-box">
        <div className="contact-container">
          <p className="tit-contact text-start">Canales Atención</p>
          <div className="row">
            {/* <div className="col-md-2"></div> */}
            <div className="col-lg-4">
              <a
                href="mailto:soporte@merkademia.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="img-contact"
                  src={require("../../assets/images/correo.jpg")}
                  alt="imagen contacto 1"
                />
              </a>
              <p className="titulo-seccion-canales">
                <a
                  href="mailto:soporte@merkademia.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mail-link"
                >
                  soporte@merkademia.com
                </a>
              </p>
            </div>
            <div className="col-lg-4">
              <a href="tel:224398893" target="_blank" rel="noopener noreferrer">
                <img
                  className="img-contact"
                  src={require("../../assets/images/fono.jpg")}
                  alt="imagen contacto 2"
                />
              </a>
              <p className="titulo-seccion-canales">
                <a
                  href="tel:224398893"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mail-link"
                >
                  Línea de soporte telefónica: 224398893
                </a>
              </p>
            </div>
            {/* <div className="col-md-2"></div> */}
            <div className="col-md-4">
              <div type="button" onClick={handleChatBox}>
                <img
                  className="img-contact"
                  src={require("../../assets/images/contact-1.jpg")}
                  alt="imagen contacto 3"
                />
              </div>
              <p className="titulo-seccion-canales">Chatea con nuestro bot</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
