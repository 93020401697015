import React from "react";
import { Layout } from "../Layout";
import banner_reglamento from "../../assets/images/banner-intro-erap.jpg";
import banner_reglamento_cell from "../../assets/images/banner-intro-erap-mobile.jpg";
import check from "../../assets/images/tic.png";
import "./styles.css";

export const InstruccionesERAP = ({ handleStartERAP }) => {
  return (
    <Layout>
      <div className="ev-header-ins">
        <h1 className="mis-d-tit text-white text-center pb-0">
          Instrucciones
          <br /> Evaluacion de Reconocimiento de Aprendizajes Previos
        </h1>
      </div>
      <div
        className="diagnostic-box"
        style={{ minHeight: `${window.screen.height * 0.44}px` }}
      >
        <div className="intro-box">
          <img
            className="imagen-que-es-merkademia"
            src={
              window.screen.width > 820
                ? banner_reglamento
                : banner_reglamento_cell
            }
            alt="banner"
          />
        </div>
        <div className="intro-box">
          <h4 className="intro-text">
            ¡Bienvenido/a a la Evaluación de Aprendizaje Previos de Merkademia!
          </h4>
          <p className="intro-text">
            <img src={check} alt="check icon" className="intro-dot-icon" />
            En Merkademia, estamos emocionados de contar contigo en esta
            evaluación que nos permitirá conocer tus conocimientos y habilidades
            en el apasionante mundo del merchandising.
          </p>
          <p className="intro-text">
            <img src={check} alt="check icon" className="intro-dot-icon" />
            Esta evaluación tiene como objetivo identificar tus fortalezas y
            áreas de oportunidad en el área específica, permitiéndonos entregar
            una experiencia de capacitación única.
          </p>
          <p className="intro-text">
            <img src={check} alt="check icon" className="intro-dot-icon" />
            Para aprobar con éxito, te animamos a dar lo mejor de ti y alcanzar
            al menos un 85% de respuestas correctas. Recuerda que cada pregunta
            es una oportunidad para aprender y crecer.
          </p>
          <p className="intro-text">
            <img src={check} alt="check icon" className="intro-dot-icon" />
            ¡Tú puedes lograrlo! Confiamos en tu capacidad y pasión por el
            merchandising. ¡Adelante, demuestra todo tu potencial en esta
            Evaluación de Aprendizaje Previos!
          </p>
          <p className="intro-text">
            <img src={check} alt="check icon" className="intro-dot-icon" />
            ¡Mucho éxito en tu camino hacia el conocimiento y el crecimiento
            profesional!
          </p>
          <p className="intro-text intro-atte">Saludos Lila y Stock</p>
        </div>
        <div className="ev-buttons-container2">
          <button
            type="button"
            className="ev-btn-send ev-btn-next"
            onClick={() => handleStartERAP()}
          >
            Empezar Evaluación
          </button>
        </div>
      </div>
    </Layout>
  );
};
