import axios from "axios";
import { moodleURL } from "../api";

export const getMoodleCategories = async ({ token }) => {
  try {
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=core_course_get_categories`
    );
    /*     console.log(data) */
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
