import React from "react";

export const TextInput = ({ label, name, type, onChange, value = "" }) => {
  return (
    <div className="mb-3" id="input-container">
      <label htmlFor={name} className="form-label text-white">
        {label}:&nbsp;
      </label>
      <div className="d-block"></div>
      {/* <label htmlFor={name} className="form-label" style={{ color: "#999" }}>
        {value}
      </label> */}
      <input
        name={name}
        type={type}
        className="form-control input-box"
        onChange={onChange}
        value={value}
        style={{ height: "50px" }}
        readOnly
      />
    </div>
  );
};
