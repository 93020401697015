import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { useMoodle } from "../../contexts/moodle";
import AlertDialog from "../AlertDialog";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";

import "./styles.css";
import { IconButton } from "@mui/material";
import { Help } from "@mui/icons-material";
import { initSenceApplication } from "../../pages/Sence/functions";

const COLOR_UNREAD_ICON = "#0079ac";
const COLOR_READ_ICON = "#b2b2b2";

export const Navbar = ({ handleHelp, nav }) => {
  const { actions, user } = useAuth();
  const auth = useAuth();
  const moodle = useMoodle();
  const navigate = useNavigate();
  const [, setNewClass] = useState(window.location.href);
  const [noticount, setNotiCount] = React.useState(null);
  const [opennotif, setOpenNotif] = React.useState(false);
  const [notifications, setNotifications] = React.useState(null);
  const [hoverBell, setHoverBell] = React.useState(false);
  const isSence = localStorage.getItem("sence_session");

  const [open, setOpen] = useState(false);

  let styles = {};

  styles.title = {
    fontSize: "14px",
    textTransform: "uppercase",
    color: "#fff",
    fontWeight: "500",
  };

  styles.tabContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: ".5rem 0",
    borderBottom: "1px solid #ccc",
    backgroundColor: "rgb(0, 121, 172)",
    margin: 0,
  };

  useEffect(() => {
    moodle &&
      user &&
      moodle
        .fetchNotifications()
        .then((res) => {
          setNotifications(res.notifications);
          setNotiCount(res.unreadcount);
        })
        .catch((err) => {
          console.log(err);
        });

    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    setNewClass("https://zoom.us/");
  }, []);

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleLogout = () => {
    actions.Logout(moodle);
    navigate("/");
  };

  const handleNavigateEv = () => {
    setOpenNotif(!opennotif);
    navigate("/mis-diagnosticos");
  };

  const handleSenceLogout = () => {
    let id = localStorage.getItem("idSence");
    if (!id) {
      return;
    }
    let sence = JSON.parse(localStorage.getItem("sence_session"));
    let nru = localStorage.getItem("nru");
    /*     console.log(sence) */
    // initSenceApplication(id, auth, sence, true, nru, null);
    initSenceApplication({
      idSence: id,
      auth,
      session: sence,
      isLogin: false,
      codCourse: nru,
      course_id: null,
    });
  };

  return (
    <nav className="navbar navbar-expand-xl navbar-container" ref={nav}>
      <div className="container-fluid">
        <img
          src={require("../../assets/images/logo-asec-white.png")}
          alt="logo ASEC"
          className="logo-navbar-desktop navbar-brand"
          onClick={() => navigate("/mis-cursos")}
        />
        <button
          className="navbar-toggler background-light"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0 ul-links width-100">
            {/* <li className="nav-item">
              <div
                className="nav-link active navbar-item"
                aria-current="page"
                onClick={() => navigate("/mis-cursos")}
              >
                Qué es Merkademia
              </div>
            </li> */}
            <li className="nav-item dropdown navbar-item">
              <div
                className="nav-link dropdown-toggle navbar-item"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Información Academica
              </div>
              <ul className="dropdown-menu">
                <li>
                  <div
                    className="dropdown-item"
                    aria-current="page"
                    onClick={() => navigate("/que-es-merkademia")}
                  >
                    Qué es Merkademia
                  </div>
                </li>
                <li>
                  <div
                    className="dropdown-item"
                    onClick={() => navigate("/mi-malla")}
                  >
                    Mi Malla
                  </div>
                </li>
                <li>
                  <div
                    className="dropdown-item"
                    onClick={() => navigate("/mi-horario")}
                  >
                    Mi Horario
                  </div>
                </li>
                <li>
                  <div
                    className="dropdown-item"
                    onClick={() => navigate("/perfil-ocupacional")}
                  >
                    Mi Perfil Ocupacional
                  </div>
                </li>
                <li>
                  <div
                    className="dropdown-item"
                    onClick={() => navigate("/reglamentos")}
                  >
                    Reglamento y Normas
                  </div>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <div
                className="nav-link active navbar-item"
                aria-current="page"
                onClick={() => navigate("/mis-cursos")}
              >
                Mis Cursos
              </div>
            </li>
            <li className="nav-item">
              <div
                className="nav-link active navbar-item"
                aria-current="page"
                onClick={() => navigate("/avance-academia")}
              >
                Avance Academia
              </div>
            </li>
            <li className="nav-item">
              <div
                className="nav-link active navbar-item"
                aria-current="page"
                onClick={() => navigate("/mis-diagnosticos")}
              >
                Mis Diagnósticos
              </div>
            </li>
            <li className="nav-item">
              <div
                className="nav-link navbar-item"
                onClick={() => navigate("/canales-atencion")}
              >
                Canales de Atención
              </div>
            </li>
            <li className="nav-item">
              <IconButton onClick={handleHelp}>
                <HelpIcon sx={{ color: "#fff" }} />
              </IconButton>
            </li>
            <li className="nav-item">
              {isSence && (
                <div
                  className="student_profile stopwatch"
                  style={{
                    cursor: "pointer",
                    justifyContent: "space-between",
                    borderRadius: "10px",
                    margin: "10px",
                    padding: "7px 10px",
                    backgroundColor: "#0086e6",
                    color: "#fff",
                  }}
                  onClick={() => handleSenceLogout()}
                >
                  {/* <img src={watch} alt="..." />
                  <p>{time}</p> */}
                  <p>Cerrar Sence</p>
                </div>
              )}
            </li>
            <li className="nav-item dropdown navbar-item blank-space">
              <div
                onMouseEnter={() => setHoverBell(true)}
                onMouseLeave={() => setHoverBell(false)}
                style={{
                  marginRight: 5,
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                {noticount > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "-12px",
                      right: "-12px",
                      backgroundColor: "#cc9114",
                      color: "#fff",
                      height: 22,
                      width: 22,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "50%",
                      transition: ".3s",
                      opacity: hoverBell ? 0.5 : 1,
                    }}
                  >
                    {/* se agrega 1 para imitar notificacion de evaluacion diagnostica */}
                    <p className="m-0">{noticount + 1}</p>
                  </div>
                )}

                <div
                  style={{
                    transition: ".5s",
                    position: "fixed",
                    width: "450px",
                    height: "100vh",
                    backgroundColor: "#d6eef5",
                    right: opennotif ? "-10px" : "-450px",
                    top: "0px",
                    boxShadow:
                      "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px",
                    zIndex: "1002",
                  }}
                >
                  <div style={styles.tabContainer}>
                    <IconButton
                      onClick={() => setOpenNotif(false)}
                      sx={{ transform: "translateX(-130px)", color: "#fff" }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <p style={styles.title}>Notificaciones</p>
                  </div>
                  {/* Se agrega componente para simular notificacion evaluacion diagnostica */}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      borderBottom: "1px solid #b2b2b2",
                      margin: 0,
                      justifyContent: "space-between",
                      padding: ".7rem .7rem",
                      height: 95,
                      backgroundColor: "#cfd8dc",
                    }}
                    onClick={() => handleNavigateEv()}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: 15,
                        alignItems: "center",
                      }}
                    >
                      <InfoIcon
                        sx={{
                          color: COLOR_UNREAD_ICON,
                          fontSize: 18,
                          marginTop: "1px",
                        }}
                      />
                      <p
                        style={{
                          fontSize: "13px",
                          color: "#605454",
                          margin: 0,
                          fontWeight: 500,
                        }}
                      >
                        Realizar Evaluación de Reconocimientos de Aprendizajes
                        previos
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: 10,
                        padding: "0 .7rem",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "11px",
                          color: "#0079ac",
                          marginLeft: 0,
                          fontWeight: 500,
                        }}
                      >
                        Hace 1 hora 37 minutos
                      </p>
                      <p
                        style={{
                          fontSize: "11px",
                          color: "#0079ac",
                          marginLeft: 10,
                          fontWeight: 500,
                        }}
                      >
                        Ver notificacion completa
                      </p>
                    </div>
                  </div>
                  {notifications &&
                    notifications.length > 0 &&
                    notifications.map((n, idx) => {
                      return (
                        <div
                          key={idx}
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            borderBottom: "1px solid #b2b2b2",
                            margin: 0,
                            justifyContent: "space-between",
                            padding: ".7rem .7rem",
                            height: 95,
                            backgroundColor: n.read ? "unset" : "#cfd8dc",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: 15,
                              alignItems: "center",
                            }}
                          >
                            <InfoIcon
                              sx={{
                                color: n.read
                                  ? COLOR_READ_ICON
                                  : COLOR_UNREAD_ICON,
                                fontSize: 18,
                                marginTop: "1px",
                              }}
                            />
                            <p
                              style={{
                                fontSize: "13px",
                                color: "#605454",
                                margin: 0,
                                fontWeight: n.read ? "unset" : 500,
                              }}
                            >
                              {n.subject}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: 10,
                              padding: "0 .7rem",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "11px",
                                color: "#0079ac",
                                marginLeft: 0,
                                fontWeight: 500,
                              }}
                            >
                              {n.timecreatedpretty}
                            </p>
                            <p
                              onClick={() => alert("hola")}
                              style={{
                                fontSize: "11px",
                                color: "#0079ac",
                                marginLeft: 10,
                                fontWeight: 500,
                              }}
                            >
                              Ver notificacion completa
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>

                <NotificationsIcon
                  onClick={() => setOpenNotif(!opennotif)}
                  sx={{
                    color: hoverBell ? "#b2b2b2" : "#fff",
                    fontSize: 28,
                    transition: ".3s",
                  }}
                />
              </div>
            </li>

            {window.screen.width > 1400 ? (
              <>
                <li className="nav-item dropdown navbar-item">
                  <div
                    className="nav-link dropdown-toggle navbar-item"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {user && user?.fullname}&nbsp;
                  </div>
                  <ul className="dropdown-menu">
                    <li>
                      <div
                        className="dropdown-item"
                        onClick={() => navigate("/mi-perfil")}
                      >
                        Mi Perfil
                      </div>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <div className="dropdown-item" onClick={handleOpen}>
                        Cerrar Sesión
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <div className="nav-link navbar-item">
                    <img
                      src={
                        user.profileimageurl.includes("?")
                          ? `${user.profileimageurl}&token=${user.token}`
                          : `${user.profileimageurl}?token=${user.token}`
                      }
                      alt="foto usuario"
                      className="img-perfil-rounded"
                      onClick={() => navigate("/mi-perfil")}
                    />
                  </div>
                </li>
              </>
            ) : (
              <li className="nav-item dropstart dropdown-center navbar-item">
                <div
                  className="nav-link dropdown-toggle navbar-item"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={
                      user.profileimageurl.includes("?")
                        ? `${user.profileimageurl}&token=${user.token}`
                        : `${user.profileimageurl}?token=${user.token}`
                    }
                    alt="foto usuario"
                    className="img-perfil-rounded"
                  />
                </div>
                <ul className="dropdown-menu">
                  <li>
                    <div
                      className="dropdown-item"
                      onClick={() => navigate("/mi-perfil")}
                    >
                      Mi Perfil
                    </div>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <div className="dropdown-item" onClick={handleOpen}>
                      Cerrar Sesión
                    </div>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </div>
      <AlertDialog
        open={open}
        title="¿Desea cerrar sesión?"
        body={""}
        handleClose={handleClose}
        handleConfirm={handleLogout}
      />
    </nav>
  );
};
