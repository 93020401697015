import React from "react";
import { ProgressChartItem } from "../ProgressChart";
import "./styles.css";

export const ProgressGroup = ({ children, courses }) => {
  if (!courses) {
    return null;
  }

  return (
    <div
      className="group-chart-container"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(4, 1fr)",
        gridTemplateRows: "auto",
        gridColumnGap: "10px",
        gridRowGap: "90px",
        /* maxWidth:'100vw', */ width: "100%",
        margin: "2rem 0 10rem 0" /* overflowX: 'scroll' */,
      }}
    >
      {courses &&
        courses.map((c, idx) => {
          return <ProgressChartItem key={idx} course={c} />;
        })}
    </div>
  );
};
