import React, { useEffect } from "react";
import { HeaderProgress } from "./Header";
import { ProgressGroup } from "./ProgressGroup";
import { ProgressTitle } from "./ProgressTitle";

import { useMoodle } from "../../contexts/moodle";
import { Layout } from "../../components/Layout";
import BasicTable from "./table";
import BasicTabs from "../../components/Tabs";

export const PROGRESS_DEFAULT = {
  title: "Historial",
  headers: [
    "Nombre curso",
    "Notas Finales",
    "Fecha Inicio",
    "Fecha Termino",
    "Encuesta",
    "Certificado",
  ],
};

export const PROGRESS_DETAIL = {
  title: "Detalle",
  headers: [
    "Evaluaciones",
    "Promedio calculado",
    "Calificacion",
    "Rango",
    "Porcentaje",
    "Retroalimentacion",
  ],
};

const Progress = () => {
  const [categories, setCategories] = React.useState(null);
  const [viewselected, setViewSelected] = React.useState(0);
  const [selectedcourses, setSelectedCourses] = React.useState(null);
  const [selectedgrades, setSelectedGrades] = React.useState(null);
  const [coursesWithGrades, setCoursesWithGrades] = React.useState(null);
  const moodle = useMoodle();

  /**
   * Get Courses
   */
  useEffect(() => {
    !moodle.courses && moodle.getCourses();
    // eslint-disable-next-line
  }, [moodle.courses]);

  /**
   * Get Categories
   */
  useEffect(() => {
    !moodle.categories && moodle.getCategories();
    moodle.categories &&
      setCategories(getCategoriesProgress(moodle.categories));
    // eslint-disable-next-line
  }, [moodle.categories]);

  /**
   * Get Progress
   */
  useEffect(() => {
    !moodle.grades && moodle.getGrades();
    // eslint-disable-next-line
  }, [moodle.grades]);

  /**
   * Set State
   */
  useEffect(() => {
    if (moodle.grades && moodle.courses && moodle.categories) {
      /**
       * Initial State
       */
      setSelectedCourses(moodle.categories[0].courses);
      setSelectedGrades(
        matchGrades(moodle.categories[0].courses, moodle.grades)
      );
    }
    // eslint-disable-next-line
  }, [moodle.grades, moodle.courses, moodle.categories]);

  /**
   * Handle Tabs State
   */
  useEffect(() => {
    if (moodle.categories && moodle.grades) {
      setCoursesWithGrades(null);
      setSelectedCourses(moodle.categories[viewselected].courses);
      setSelectedGrades(
        matchGrades(moodle.categories[viewselected].courses, moodle.grades)
      );
    }
    // eslint-disable-next-line
  }, [viewselected]);

  function getCategoriesProgress(categoriesData) {
    let output = [];
    let sum = 0;
    for (let category of categoriesData) {
      for (let course of category.courses) {
        sum = sum + course.progress;
      }

      output = [
        ...output,
        {
          displayname: category.name,
          progress: sum / category.courses.length,
        },
      ];

      sum = 0;
    }

    return output;
  }

  function matchGrades(courses, grades) {
    let gradesids = grades.map((g) => g.courseid);
    let coursesids = courses.map((c) => c.id);
    let matchingcourses = courses.filter((c) => gradesids.includes(c.id));

    if (matchingcourses.length < 1) {
      setCoursesWithGrades(null);
    }
    setCoursesWithGrades(matchingcourses);
    let matchingrades = grades.filter((g) => coursesids.includes(g.courseid));
    if (matchingrades.length < 1) {
      return null;
    }
    return matchingrades;
  }

  return (
    <Layout>
      <div style={styles.progressBlock}>
        <BasicTabs
          tabs={moodle.categories}
          viewselected={viewselected}
          setViewSelected={setViewSelected}
        />
        <ProgressGroupCategoriesTab
          courses={selectedcourses}
          grades={selectedgrades}
          coursesWithGrades={coursesWithGrades}
        />
        <HeaderProgress />

        <ProgressGroup courses={categories} />
      </div>
    </Layout>
  );
};

const ProgressGroupCategoriesTab = ({ courses, grades, coursesWithGrades }) => {
  return (
    <>
      <div style={styles.wrapper}>
        <ProgressTitle title={PROGRESS_DEFAULT.title} />
        <BasicTable
          headers={PROGRESS_DEFAULT.headers}
          courses={coursesWithGrades}
          grades={grades}
        />
      </div>
    </>
  );
};
const styles = {
  wrapper: {
    paddingTop: "2.5rem",
  },
  progressBlock: {
    justifyContent: "center",
    width: "100%",
    padding: "0 7rem",
  },
};

export default Progress;
