import axios from "axios";
import { useMoodle } from "./moodle";
import { createContext, useContext, useState } from "react";

const MallasContext = createContext(null);

export const MallasProvider = ({ children }) => {
  const moodle = useMoodle();
  const [malla, setMalla] = useState(null);

  /**
   * Requirements to create, display, and assign a malla
   *
   *
   * Ability to retrieve moodle courses
   *
   *
   * Ability to create categories
   *
   *
   * Ability to create levels
   *
   *
   * Ability to add category to course
   *
   *
   * Ability to add course to level
   *
   *
   * Ability to add Category of courses to the malla
   */

  /*   let actions = {}; */

  let actions = {};

  actions.populateMalla = function ({ malla }) {
    let normalizeMalla = malla;
    for (let individualmalla of normalizeMalla.malla) {
      for (let i = 0; i < individualmalla.courses.length; i++) {
        let fullcourse = moodle.courses.filter(
          (c) => c.id === Number(individualmalla.courses[i].course_id)
        )[0];
        if (fullcourse) {
          individualmalla.courses[i] = {
            ...individualmalla.courses[i],
            name: fullcourse.fullname,
          };
        }
      }
    }

    setMalla(normalizeMalla);
  };

  actions.fetchInitialMalla = async function () {
    try {
      let { data } = await axios.get(
        "https://mallas-merkademia.iapp.cl/api/malla/merkademia/malla/get/50"
      );
      actions.populateMalla({ malla: data[0] });
    } catch (err) {
      console.log(err);
    }
  };

  let value = {
    malla,
    fetchInitialMalla: actions.fetchInitialMalla,
  };

  return (
    <MallasContext.Provider value={value}>{children}</MallasContext.Provider>
  );
};

export const useMallas = () => useContext(MallasContext);
