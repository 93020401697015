import React from "react";
import "./styles.css";

export const TipddyFooter = () => {
  return (
    <>
      <div className="footer-tipddy">
        <p className="text-tipddy">
          Red e-learning Tipddy
          <img
            src={require("../../assets/images/logo-tipddy.png")}
            alt="..."
            className="img-rounded"
          />
        </p>
      </div>
      <script src="https://cdn.jsdelivr.net/npm/@fancyapps/ui@5.0/dist/fancybox/fancybox.umd.js"></script>
    </>
  );
};
