import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "../../components/Layout";

export const MoodleWebView = () => {
  let location = useLocation();
  let navigate = useNavigate();

  console.log({ location });

  useEffect(() => {
    if (!location.state?.url) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Layout footer={false}>
      <iframe
        src={location.state.url}
        frameBorder="0"
        width="100%"
        allow="autoplay"
        title="video"
        style={{ height: "100vh" }}
      />
    </Layout>
  );
};
