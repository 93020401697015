import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const StyledTab = styled(Tab)({
  "&.Mui-selected": {
    color: "#ff9c00ed",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: {
      color: "#b2b2b2",
      width: "25%",
    },
  };
}

export default function BasicTabs({ tabs, viewselected, setViewSelected }) {
  const handleChange = (event, newValue) => {
    setViewSelected(newValue);
  };

  React.useEffect(() => {
    let tabsGroup = document.querySelector(".MuiTabs-centered");

    if (tabsGroup) {
      tabsGroup.style.alignItems = "center";
    }
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        margin: "2.5rem 0",
        borderBottom: "1px solid #ff9c00ed",
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Tabs
          value={viewselected}
          onChange={handleChange}
          aria-label="basic tabs exampl"
          TabIndicatorProps={{ style: { background: "#ff9c00ed" } }}
          sx={{ width: "100%" }}
          orientation={window.screen.width <= 1250 ? "vertical" : "horizontal"}
          centered={true}
        >
          {tabs &&
            tabs.map((tab, idx) => {
              return (
                <StyledTab
                  label={tab.name}
                  {...a11yProps(idx)}
                  key={idx}
                  style={{
                    height: 70,
                    borderRadius: "0",
                    width:
                      window.screen.width > 820 && window.screen.width < 1560
                        ? `${100 / tabs.length}%`
                        : "100%",
                  }}
                />
              );
            })}
        </Tabs>
      </Box>
    </Box>
  );
}
