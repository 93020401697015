import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import volver_flecha from "../../assets/images/volver-flecha.png";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../../components/AlertDialog";
import questionsCE from "./questionsCE.json";
import questionsAD from "./questionsAD.json";
import { SNACKTYPES, useSnack } from "../../contexts/snack";
import { useTimer } from "../../components/TimerEv";
import { LIMIT_CRON_AVG_ERAP } from "../../utils/state";
import { InstruccionesERAP } from "../../components/InstruccionesERAP";
import { ResultadosERAP } from "../../components/ResultadosERAP";
import { fetchErapById } from "../../services/tipddy/dashboard";

export const InteriorDiagnostico2 = () => {
  const [evData, setEvData] = useState(null);
  const [data /* , setData */] = useState([questionsAD, questionsCE]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const [open2, setOpen2] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answerChecked, setAnswerChecked] = useState(null);
  const [showInstructions, setShowInstructions] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [results, setResults] = useState(null);
  const [evID, setEvID] = useState(null);
  const [, handleSnack] = useSnack();
  const { time, limit } = useTimer();

  const shuffleAndPickAnswers = (data) => {
    let new_array = data.questions;
    for (let i = new_array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = new_array[i];
      new_array[i] = new_array[j];
      new_array[j] = temp;
    }
    if (data.questions.length >= 20) {
      data.questions = new_array.slice(0, 20);
    }

    return data;
  };

  useEffect(() => {
    /* agregar logica de dato evaluacion */
    const ev_param = 0;
    let new_questions = shuffleAndPickAnswers(data[ev_param]);
    setEvData(new_questions);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const url = window.location.href;
    const urlSearchParams = new URLSearchParams(url.split("?")[1]); // get the URLSearchParams object for the query string parameters
    const giftIds = [];
    urlSearchParams.forEach((value, key) => {
      if (key.startsWith("gift_id[")) {
        const index = parseInt(key.match(/\[(\d+)\]/)[1]); // get the index from the parameter name
        giftIds[index] = value;
      }
    });
    setEvID(urlSearchParams.get("name"));
  }, []);

  useEffect(() => {
    if (limit > LIMIT_CRON_AVG_ERAP) {
      handleErapLogout();
      localStorage.removeItem("last_login_erap");
    }
    //eslint-disable-next-line
  }, [time]);

  useEffect(() => {
    if (evID) {
      fetchErapById(evID).then((res) => {
        console.log("fetchErapById", res);
      });
    }
  }, [evID]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const handleOpen2 = () => setOpen2(true);
  // const handleClose2 = () => setOpen2(false);

  const handleCancel = () => {
    localStorage.removeItem("last_login_erap");
    navigate("/mis-diagnosticos");
  };

  const handleSend = () => {
    /* futura logica de publicar y traer respuestas */
    let data = {
      correctAnswers: "17",
      totalAnswers: "20",
      approved: true,
    };
    setResults(data);
    setShowResults(true);
  };

  const handleNextQuestion = () => {
    if (!answerChecked) {
      return handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "Debes seleccionar una respuesta antes de continuar",
        },
      });
    }
    setAnswerChecked(null);
    currentQuestion + 1 < evData.questions.length &&
      setCurrentQuestion(currentQuestion + 1);
  };

  useEffect(() => {
    if (currentQuestion === 0) {
      let q = document.querySelector(`#question-${currentQuestion}`);
      if (q) {
        q.style.display = "unset";
      }
    } else {
      let b = document.querySelector(`#question-${currentQuestion - 1}`);
      let q = document.querySelector(`#question-${currentQuestion}`);
      if (q && b) {
        b.style.display = "none";
        q.style.display = "unset";
      }
    }
  }, [currentQuestion]);

  const handleCatchAnswer = (e, i) => {
    let checkbox_id = e.target.id;
    let question_id = i;
    console.log("answer seleccionado", checkbox_id, question_id);
    if (checkbox_id !== answerChecked) {
      setAnswerChecked(checkbox_id);
      for (let i = 0; i <= 3; i++) {
        if (`answer-${question_id}-${i}` !== checkbox_id) {
          let otherQuestion = document.querySelector(
            `#answer-${question_id}-${i}`
          );
          if (otherQuestion) {
            otherQuestion.checked = false;
          }
        }
      }
    }
  };

  const handleErapLogout = () => {
    handleSnack({
      type: SNACKTYPES.error,
      payload: {
        message: "Se agotó el tiempo",
      },
    });
    navigate("/mis-diagnosticos");
  };

  const handleStartERAP = () => {
    if (!localStorage.getItem("last_login_erap")) {
      localStorage.setItem("last_login_erap", new Date());
    }
    setShowInstructions(false);
  };

  const handleExitERAP = () => {
    localStorage.removeItem("last_login_erap");
    setShowResults(false);
    navigate("/mis-diagnosticos");
  };

  if (showInstructions) {
    return <InstruccionesERAP handleStartERAP={handleStartERAP} />;
  }

  if (showResults) {
    return <ResultadosERAP handleExitERAP={handleExitERAP} results={results} />;
  }

  return (
    <Layout>
      <div className="ev-header">
        <div className="go-back pt-0 ms-0" onClick={handleOpen}>
          <img src={volver_flecha} alt="img-flecha-volver" />
          Volver
        </div>
        <div className="erap-timer">{time}</div>
      </div>
      {evData ? (
        <div
          className="diagnostic-box"
          style={{ minHeight: `${window.screen.height * 0.58}px` }}
        >
          <h1 className="diagnostic-title">{evData.title}</h1>
          <div className="question-counter">
            ( {currentQuestion + 1} / {evData.questions.length} )
          </div>
          {evData.questions.length > 0 &&
            evData.questions.map((e, i) => (
              <div
                className={
                  i === currentQuestion
                    ? "diagnostic-container"
                    : "diagnostic-container diagnostic-container2"
                }
                key={i}
                id={`question-${i}`}
              >
                <div className="header-erap">
                  <h4 className="tit-question">
                    {i + 1}. {e.title}
                  </h4>
                </div>
                <div className="linea-naranja-int"></div>
                {e.answer_type === "single" &&
                  e.options.map((element, idx) => (
                    <div className="option-container" key={idx}>
                      <input
                        className="checkbox-ev"
                        type="checkbox"
                        id={`answer-${i}-${idx}`}
                        onChange={(event) => handleCatchAnswer(event, i)}
                      />
                      <label className="label-ev">{element.title}</label>
                    </div>
                  ))}
              </div>
            ))}
          <div className="ev-buttons-container2">
            {currentQuestion + 1 === evData.questions.length ? (
              <button
                type="button"
                className="ev-btn-send ev-btn-next"
                onClick={() => handleSend()}
              >
                Finalizar
              </button>
            ) : (
              <button
                type="button"
                className="ev-btn-send ev-btn-next"
                onClick={() => handleNextQuestion()}
              >
                Siguiente
              </button>
            )}
          </div>
        </div>
      ) : (
        /* agregar skeleton loader a futuro */
        <div>Cargnado . . .</div>
      )}
      <AlertDialog
        open={open}
        title="¿Desea volver?"
        body={"se perderá el progreso de la evaluación"}
        handleClose={handleClose}
        handleConfirm={handleCancel}
        bgColor={"red"}
      />
      {/* <AlertDialog
        open={open2}
        title="¿Desea enviar la evaluación?"
        body={"no podrá cambiar el resultado"}
        handleClose={handleClose2}
        handleConfirm={handleSend}
      /> */}
    </Layout>
  );
};
