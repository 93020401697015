import React from "react";
import "./styles.css";
import { Layout } from "../../components/Layout";
import competencia_1 from "../../assets/images/competencia_1.png";
import competencia_2 from "../../assets/images/competencia_2.png";
import competencia_3 from "../../assets/images/competencia_3.png";
import competencia_4 from "../../assets/images/competencia_4.png";
import perfil_ocupacional from "../../assets/docs/reglamento.pdf";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./styles.css";

export const PerfilOcupacional = () => {
  React.useEffect(() => {
    Fancybox.bind("[data-fancybox]");
  }, []);
  return (
    <Layout>
      <div className="perfil-box">
        <div className="perfil-container">
          <p className="tit-perfil">Mi Perfil Ocupacional</p>
          <div
            className="row"
            style={{ marginBottom: "3rem", marginTop: "3rem" }}
          >
            <div className="col-sm-6 col-lg-3">
              <img src={competencia_1} alt="foto curso" className="img-curso" />
            </div>
            <div className="col-sm-6 col-lg-3">
              <img src={competencia_2} alt="foto curso" className="img-curso" />
            </div>
            <div className="col-sm-6 col-lg-3">
              <img src={competencia_3} alt="foto curso" className="img-curso" />
            </div>
            <div className="col-sm-6 col-lg-3">
              <img src={competencia_4} alt="foto curso" className="img-curso" />
            </div>
          </div>
          <p className="texto-perfil">
            Se le denomina mercaderista a aquellas personas que realiza las
            labores de demostración de bienes y servicios en establecimientos
            comerciales generalmente de consumo masivo; acopian información en
            forma directa o utilizando medios informáticos.
          </p>
          <p className="texto-perfil">
            Su principal objetivo es exhibir en forma ordenada la mayor cantidad
            de mercancía, de todas las referencias anunciadas por la firma
            representada; tomando en cuenta los reglamentos y sugerencias del
            establecimiento comercial. adicionalmente prestara mantenimiento a
            las exhibiciones, actualización de etiquetas, relación de
            inventario, control de ordenes de compra, recepción de despachos,
            relación de merma, información de marca, etc
          </p>
          <p className="texto-perfil">
            Su ámbito ocupacional es supermercados y grandes tiendas de retail.
          </p>
          <div className="buttons-container">
            {/* <a
              data-fancybox="reglamentos"
              href={perfil_ocupacional}
              type="button"
              className="btn-reglamento"
            >
              Ver Perfil Ocupacional
            </a> */}
            <a
              href={perfil_ocupacional}
              target="_blank"
              rel="noreferrer"
              type="button"
              className="btn-reglamento"
            >
              Descargar Perfil Ocupacional
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};
