import axios from "axios";
import { moodleURL, tipddytoken } from "../api";

export const getUserCourses = async (token, userid) => {
  try {
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=core_enrol_get_users_courses&userid=${userid}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getCoreCourseContent = async (args) => {
  try {
    const { token, course_id } = args;
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=core_course_get_contents&courseid=${course_id}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getCourseUsersInfo = async (args) => {
  try {
    const { token, course_id } = args;
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=core_group_get_course_user_groups&courseid=${course_id}`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getUsers = async (token) => {
  try {
    const { data } = await axios.get(
      `${moodleURL}${tipddytoken}&moodlewsrestformat=json&wsfunction=core_user_get_users&criteria[0][key]=lastname&criteria[0][value]=%`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export const getOverviewGrades = async (token) => {
  try {
    const { data } = await axios.get(
      `${moodleURL}${token}&moodlewsrestformat=json&wsfunction=gradereport_overview_get_course_grades`
    );
    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};
