import axios from "axios";
import { iappUrl } from "../api";

export async function getUserNru(courseid, userid) {
  try {
    let { data } = await axios.get(`${iappUrl}/${courseid}/${userid}`);
    console.log("nru data: ", data);
    if (data.length === 0) {
      return null;
    }
    return data[0].codcourse;
  } catch (err) {
    console.log(err);
    return null;
  }
}
