import axios from "axios";
import { dashboardURL } from "../api";

export const listAds = async (client_id) => {
  try {
    let { data } = await axios.get(
      `${dashboardURL}/client/ads?client=${client_id}`
    );
    return data.result;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const fetchEraps = async () => {
  try {
    let { data } = await axios.get(`${dashboardURL}/client/quizzes?client=43`);
    return data.result;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

export const fetchErapById = async (id) => {
  try {
    let { data } = await axios.get(
      `${dashboardURL}/client/quiz?client=43&postname=${id}`
    );
    console.log("fetchErapById service:", data);
    return data.result;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};
