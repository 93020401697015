export function setSenceSession(isLogin) {
  let session = {};
  let search = document.URL.split("?")[1];
  let search_params = search.split("&");
  console.log({ search, search_params });
  search_params.forEach((param) => {
    let key = param.split("=")[0];
    let value = param.split("=")[1];
    session[key] = value;
  });
  if (isLogin) {
    localStorage.setItem("sence_session", JSON.stringify(session));
  }
  console.log({ session });
  return session;
}
