import React from "react";
import { Layout } from "../../components/Layout";
import banner_reglamento from "../../assets/images/banner-normas.jpg";
import banner_reglamento_cell from "../../assets/images/banner-normas-cel.jpg";
import reglamento from "../../assets/docs/reglamento.pdf";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./styles.css";

export const Reglamentos = () => {
  React.useEffect(() => {
    Fancybox.bind("[data-fancybox]");
  }, []);
  return (
    <Layout>
      <div className="perfil-box">
        <div className="perfil-container">
          <p className="tit-perfil">Reglamento y Normas</p>
          <img
            className="imagen-que-es-merkademia"
            src={
              window.screen.width > 1000
                ? banner_reglamento
                : banner_reglamento_cell
            }
            alt="banner reglamento"
          />
          <div className="text-container">
            <p className="texto-reglamento">
              El reglamento general de formación de la Academia de mercaderista
              “Merkademia” impartido por el OTEC Asesoría Educacional ASEC® S.A,
              establece las normas que regirán las acciones formativas de las
              unidades de competencia realizadas por los /las participantes de
              empresas u organizaciones del sector público o privado con el
              objeto de complementar y consolidar su formación, otorgando las
              orientaciones necesarias para el ejercicio de su oficio u
              profesión. Esto sin prejuicio de las definiciones particulares que
              establezca cada una de las acciones formativas.
            </p>
            <p className="texto-reglamento">
              En conformidad del plan formativo de Merkademia, se entenderá...
            </p>
            <div className="fade-container"></div>
          </div>
          <div className="buttons-container">
            <a
              data-fancybox="reglamentos"
              href={reglamento}
              // target="_blank"
              // rel="noreferrer"
              type="button"
              className="btn-reglamento"
            >
              Ver Reglamentos y Normas
            </a>
            <a
              href={reglamento}
              target="_blank"
              rel="noreferrer"
              type="button"
              className="btn-reglamento"
            >
              Descargar Reglamentos y Normas
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};
