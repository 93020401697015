/* 
RutOtec=76299492-5
Token=42AC9AD5-F09B-45B8-A350-23EE9B82DA3C
CodSence=1238004415
CodigoCurso=6200761
LineaCapacitacion=3
RunAlumno=18768227-4
IdSesionAlumno={{Id-sesion-en-LMS}}
UrlRetoma=http://xxxx/exito
UrlError=http://xxxx/error 
*/

let env = "prod";

let sence = {
  dev: {
    RUT_OTEC: "96609350-1",
    COD_SENCE: "1238004415",
    CODIGO_CURSO: "6205890",
    LINEA_CAPACITACION: "3",
    TOKEN: "ECE523C2-E85D-4241-9E7D-7A37FDCCF240",
    URL_LOGIN: "https://sistemas.sence.cl/rce/Registro/IniciarSesion",
    URL_LOGOUT: "https://sistemas.sence.cl/rce/Registro/CerrarSesion",
    URL_RETURN_LOGIN:
      "https://dev2.tipddy.cl/tipddy-web/sence-merkademia/sence-login-dev.php",
    URL_RETURN_LOGOUT:
      "https://dev2.tipddy.cl/tipddy-web/sence-merkademia/sence-logout-dev.php",
    URL_LOGIN_ERROR:
      "https://dev2.tipddy.cl/tipddy-web/sence-merkademia/sence-login-error-dev.php",
    URL_LOGOUT_ERROR:
      "https://dev2.tipddy.cl/tipddy-web/sence-merkademia/sence-logout-error-dev.php",
  },
  prod: {
    RUT_OTEC: "96609350-1",
    COD_SENCE: "1238004415",
    CODIGO_CURSO: "6205890",
    LINEA_CAPACITACION: "3",
    TOKEN: "ECE523C2-E85D-4241-9E7D-7A37FDCCF240",
    URL_LOGIN: "https://sistemas.sence.cl/rce/Registro/IniciarSesion",
    URL_LOGOUT: "https://sistemas.sence.cl/rce/Registro/CerrarSesion",
    URL_RETURN_LOGIN:
      "https://dev2.tipddy.cl/tipddy-web/sence-merkademia/sence-login.php",
    URL_RETURN_LOGOUT:
      "https://dev2.tipddy.cl/tipddy-web/sence-merkademia/sence-logout.php",
    URL_LOGIN_ERROR:
      "https://dev2.tipddy.cl/tipddy-web/sence-merkademia/sence-login-error.php",
    URL_LOGOUT_ERROR:
      "https://dev2.tipddy.cl/tipddy-web/sence-merkademia/sence-logout-error.php",
  },
};

export const sence_config = {
  RUT_OTEC: sence[env].RUT_OTEC,
  COD_SENCE: sence[env].COD_SENCE,
  CODIGO_CURSO: sence[env].CODIGO_CURSO,
  LINEA_CAPACITACION: sence[env].LINEA_CAPACITACION,
  TOKEN: sence[env].TOKEN,
  URL_LOGIN: sence[env].URL_LOGIN,
  URL_LOGOUT: sence[env].URL_LOGOUT,
  URL_RETURN_LOGIN: sence[env].URL_RETURN_LOGIN,
  URL_RETURN_LOGOUT: sence[env].URL_RETURN_LOGOUT,
  URL_LOGIN_ERROR: sence[env].URL_LOGIN_ERROR,
  URL_LOGOUT_ERROR: sence[env].URL_LOGOUT_ERROR,
};

export const login_result = {
  CodSence: "",
  CodigoCurso: "",
  IdSesionAlumno: "",
  IdSesionSence: "",
  RunAlumno: "",
  FechaHora: "",
  ZonaHoraria: "",
  LineaCapacitacion: "",
};
