import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Snackbar } from "./components/SnackBar";
import { useAuth } from "./contexts/auth";
import { useSnack } from "./contexts/snack";
import { SenceApp } from "./pages/Sence/form";
import { PrivateRoute } from "./routes/auth";
import { routes } from "./routes/routes";

function App() {
  const [snack] = useSnack();
  const auth = useAuth();

  React.useEffect(() => {
    if (!auth.user) {
      auth.actions.VerifyAuth();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => {
            const Element = route.requireAuth ? (
              <PrivateRoute>
                <route.element />
              </PrivateRoute>
            ) : (
              <route.element />
            );
            return <Route path={route.path} element={Element} key={index} />;
          })}
        </Routes>
      </BrowserRouter>
      <Snackbar {...snack} />
      <SenceApp />
    </>
  );
}

export default App;
