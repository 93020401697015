import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import eap_banner_desktop from "../../assets/images/img-banner-eap.jpg";
import eap_banner_mobile from "../../assets/images/img-banner-eap-mobile.jpg";
import "./styles.css";

export const CarouselBanner = ({ array }) => {
  const [eap /*, setEap */] = useState(true);
  const [bannerArray, setBannerArray] = useState([...array]);

  let eap_banner = {
    link_ads: "https://www.merkademia.com/mis-diagnosticos",
    // link_ads: "http://localhost:3000/mis-diagnosticos",
    images: {
      image_desktop: eap_banner_desktop,
      image_mobile: eap_banner_mobile,
    },
  };

  useEffect(() => {
    /* agregar logica para desplegar o no banner de Evaluacion de aprendizajes previos (eap) */
    if (eap) {
      setBannerArray([eap_banner, ...array]);
      // console.log("final banner array", [eap_banner, ...array]);
    }
    //eslint-disable-next-line
  }, [array]);

  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-bs-ride="true"
    >
      <div className="carousel-indicators">
        {bannerArray.map((e, idx) => (
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={idx}
            className={idx === 0 ? "active" : ""}
            aria-current="true"
            aria-label={`Slide ${idx}`}
            key={idx}
          ></button>
        ))}
      </div>
      <div className="carousel-inner">
        {/* se cambio > 0 => > 1, mientras no exista logica de eap */}
        {bannerArray.length > 0 ? (
          bannerArray.map((element, index) => (
            <div
              className={index === 0 ? "carousel-item active" : "carousel-item"}
              key={index}
              onClick={() => window.location.assign(element.link_ads)}
            >
              <img
                src={
                  window.screen.width > 820
                    ? element.images.image_desktop
                    : element.images.image_mobile
                }
                className="d-block w-100 img-banner"
                alt={`imagen banner ${index}`}
              />
            </div>
          ))
        ) : (
          <CardBannerSkeleton />
        )}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

const CardBannerSkeleton = () => {
  return (
    <Skeleton
      baseColor="#cccccc"
      className="img-banner"
      style={{
        opacity: 0.5,
        width: "100%",
        marginBottom: "1rem",
        height: "400px",
      }}
    />
  );
};
