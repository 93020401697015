import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import { useAuth } from "../../contexts/auth";
import { DEFAULT_PROFILE } from "../../utils/state";
import { TextInput } from "../../components/TextInput";
import "./styles.css";

export const MiPerfil = () => {
  const [credentials, setCredentials] = useState(DEFAULT_PROFILE);
  const { user } = useAuth();

  useEffect(() => {
    setCredentials({
      ...credentials,
      fullname: user.fullname,
      idnumber: user.idnumber,
      institution: user.institution,
      position: user.customfields ? user.customfields[0].value : "",
      address: user.address,
      phone1: user.phone1,
      phone2: user.phone2,
      email: user.email,
    });
    console.log(user);
    // eslint-disable-next-line
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setCredentials({ ...credentials, [name]: value });
    console.log(credentials);
  };

  return (
    <Layout>
      <div className="profile-box">
        <div className="profile-container">
          <p className="tit-profile">Datos Personales</p>
          <div className="row top-profile-section">
            <div className="col-lg-3 col-xxl-2">
              <img
                src={
                  user.profileimageurl.includes("?")
                    ? `${user.profileimageurl}&token=${user.token}`
                    : `${user.profileimageurl}?token=${user.token}`
                }
                alt="foto perfil"
                className="profile-img"
              />
            </div>
            <div className="col-lg-4 col-xxl-3" style={{ marginTop: "2.5rem" }}>
              <div
                className="form-label text-white"
                style={{ fontSize: "20px" }}
              >
                Nombre: &nbsp;
                <span style={{ color: "#999" }}>{user.fullname}</span>
              </div>
              <div
                className="form-label text-white"
                style={{ fontSize: "20px" }}
              >
                Rut: &nbsp;
                <span style={{ color: "#999" }}>{user.idnumber}</span>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "2rem" }}>
            <div className="col-md-6">
              <TextInput
                label={"Empresa"}
                name={"institution"}
                type={"text"}
                onChange={handleChange}
                value={credentials.institution}
              />
              <TextInput
                label={"Cargo"}
                name={"position"}
                type={"text"}
                onChange={handleChange}
                value={credentials.position}
              />
              <TextInput
                label={"Dirección"}
                name={"address"}
                type={"text"}
                onChange={handleChange}
                value={credentials.address}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                label={"Teléfono 1"}
                name={"phone1"}
                type={"number"}
                onChange={handleChange}
                value={credentials.phone1}
              />
              <TextInput
                label={"Teléfono 2"}
                name={"phone2"}
                type={"number"}
                onChange={handleChange}
                value={credentials.phone2}
              />
              <TextInput
                label={"Correo"}
                name={"email"}
                type={"email"}
                onChange={handleChange}
                value={credentials.email}
              />
            </div>
            <div className="mb-3 d-flex">
              <button
                type="button"
                className="btn btn-warning ms-auto me-0 mt-3 btn-update"
              >
                Actualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
