import { MIME_TYPES, MOODLE_STRINGS, TIPDDY_MODULES } from "./state";

export function normalizer(data, course_id = null) {
  console.log({ data });

  let programModules = [];
  let onlineModules = [];
  let normalizeModules = data.filter((module) => module.modules.length > 0);
  let schedule = [];
  /**
   * @function loop
   * Receive the modules and for each module find the [resources]
   *
   */
  for (let module of normalizeModules) {
    module.modules = module.modules.filter(
      (r) => r.modname !== TIPDDY_MODULES.label
    );
    /**
     *
     * @function loop
     * For each resource normalize its type for the app
     *
     */

    module = getRawProgress(module);

    for (let resource of module.modules) {
      let newresource = resource;

      /**
       * Option for resource with no tracking feature set to default as completed
       */
      if (newresource.completion === 0) {
        newresource.isTracked = false;
        newresource.isCompleted = true;
      }

      /**
       * For any king of completion config set tracking feature to true
       */
      if (newresource.completion !== 0 && newresource.completiondata) {
        newresource.isTracked = true;
        /**
         * Verify for any kind of config the completion status
         */
        if (newresource.completiondata.state !== 0) {
          newresource.isCompleted = true;
        }
        /**
         * Incomplete status
         */
        if (newresource.completiondata.state === 0) {
          newresource.isCompleted = false;
        }
      }

      /**
       *
       * @type { FORUM }
       * Forum type for the moment just set the prop tipddyname as standard
       *
       */
      if (newresource.modname === TIPDDY_MODULES.forum) {
        newresource.tipddyname = TIPDDY_MODULES.forum;
      }
      /**
       *
       * @type { CHOICE }
       *
       */
      if (newresource.modname === TIPDDY_MODULES.choice) {
        newresource.tipddyname = TIPDDY_MODULES.choice;
      }
      /**
       *
       * @type { URL }
       *
       */
      if (newresource.modname === TIPDDY_MODULES.url) {
        newresource.tipddyname = TIPDDY_MODULES.url;
      }
      /**
       *
       * @type { URL:VIMEO }
       *
       */
      if (
        newresource.modname === TIPDDY_MODULES.url &&
        newresource.contents[0].fileurl.includes("vimeo")
      ) {
        newresource.tipddyname = TIPDDY_MODULES.vimeo;

        let vimeodata = newresource.contents[0].fileurl.split("/");
        newresource.video_id = vimeodata[vimeodata.length - 1];

        if (vimeodata.length > 4) {
          newresource.video_h = vimeodata[vimeodata.length - 1];
          newresource.video_id = vimeodata[vimeodata.length - 2];
        }
      }
      /**
       *
       * @type { URL:ZOOM }
       *
       */
      if (
        newresource.modname === TIPDDY_MODULES.url &&
        newresource.contents[0].fileurl.includes("zoom")
      ) {
        newresource.tipddyname = TIPDDY_MODULES.zoom;
        newresource.onlineurl = newresource.contents[0].fileurl;
        newresource.tipddytype = MOODLE_STRINGS.ONLINE;

        onlineModules = [...onlineModules, newresource];
      }
      /**
       *
       * @type { URL:TEAMS }
       *
       */
      if (
        newresource.modname === TIPDDY_MODULES.url &&
        newresource.contents[0].fileurl.includes("teams")
      ) {
        newresource.tipddyname = TIPDDY_MODULES.teams;
        newresource.onlineurl = newresource.contents[0].fileurl;
        newresource.tipddytype = MOODLE_STRINGS.ONLINE;

        onlineModules = [...onlineModules, newresource];
      }
      /**
       *
       * @type { URL:MEET }
       *
       */
      if (
        newresource.modname === TIPDDY_MODULES.url &&
        newresource.contents[0].fileurl.includes("meet.google")
      ) {
        newresource.tipddyname = TIPDDY_MODULES.meet;
        newresource.onlineurl = newresource.contents[0].fileurl;
        newresource.tipddytype = MOODLE_STRINGS.ONLINE;

        onlineModules = [...onlineModules, newresource];
      }

      /**
       *
       * @type { STORAGE }
       *
       */
      if (newresource.modname === TIPDDY_MODULES.data) {
        newresource.tipddyname = TIPDDY_MODULES.data;
      }
      /**
       *
       * @type { CERT }
       *
       */
      if (newresource.modname === TIPDDY_MODULES.customcert) {
        newresource.tipddyname = TIPDDY_MODULES.customcert;
      }
      /**
       *
       * @type { PDF }
       * For the PDF type se the tipddyname , pass the file to direct handling and mimetype
       *
       */
      if (
        newresource.modname === TIPDDY_MODULES.resource &&
        newresource.contents.length > 0 &&
        newresource.contents[0].mimetype === MIME_TYPES.pdf
      ) {
        newresource.tipddyname = TIPDDY_MODULES.pdf;
        newresource.tipddyurl = newresource.contents[0].fileurl.split("?")[0];
        newresource.tipddymimetype = newresource.contents[0].mimetype;
      }
      /**
       *
       * @type { IMAGE/PNG }
       * For the PDF type se the tipddyname , pass the file to direct handling and mimetype
       *
       */
      if (
        newresource.modname === TIPDDY_MODULES.resource &&
        newresource.contents.length > 0 &&
        newresource.contents[0].mimetype === MIME_TYPES.png
      ) {
        newresource.tipddyname = TIPDDY_MODULES.png;
        newresource.tipddyurl = newresource.contents[0].fileurl.split("?")[0];
        newresource.tipddymimetype = newresource.contents[0].mimetype;
      }
      /**
       *
       * @type { QUIZ }
       * Quiz time handle dates if necesary
       *
       */
      if (newresource.modname === TIPDDY_MODULES.quiz) {
        newresource.tipddyname = TIPDDY_MODULES.quiz;
        /**
         *
         * Verify if has Dates for the open and close Assignment
         *
         */
        if (newresource.dates.length > 0) {
          let open = newresource.dates.find(
            (d) => d.dataid === MOODLE_STRINGS.TIMEOPEN
          );
          let close = newresource.dates.find(
            (d) => d.dataid === MOODLE_STRINGS.TIMECLOSE
          );

          /**
           *
           * With the dates check for the active or inactive status
           *
           */
          if (open && close) {
            let today = new Date();
            let openDate = new Date(open.timestamp * 1000);
            let closeDate = new Date(close.timestamp * 1000);

            let isClose = closeDate < today;
            let isOpen = openDate < today && !isClose;

            newresource.isopen = isOpen;
            newresource.isclose = isClose;
            newresource.tipddyopen = open.timestamp;
            newresource.tipddyclose = close.timestamp;
          }
        }
      }

      /**
       *
       * @type { FEEDBACK }
       * Feedback and handling dates if necesary
       *
       */
      if (newresource.modname === TIPDDY_MODULES.feedback) {
        newresource.tipddyname = TIPDDY_MODULES.feedback;

        if (newresource.dates.length > 0) {
          let open = newresource.dates.find(
            (d) => d.dataid === MOODLE_STRINGS.TIMEOPEN
          );
          let close = newresource.dates.find(
            (d) => d.dataid === MOODLE_STRINGS.TIMECLOSE
          );

          /**
           *
           * With the dates check for the active or inactive status
           *
           */
          if (open && close) {
            let today = new Date();
            let openDate = new Date(open.timestamp * 1000);
            let closeDate = new Date(close.timestamp * 1000);

            let isClose = closeDate < today;
            let isOpen = openDate < today && !isClose;

            newresource.isopen = isOpen;
            newresource.isclose = isClose;
            newresource.tipddyopen = open.timestamp;
            newresource.tipddyclose = close.timestamp;
          }
        }
      }
      /**
       *
       * @type { SCORM }
       * Feedback and handling dates if necesary
       *
       */
      if (newresource.modname === TIPDDY_MODULES.scorm) {
        newresource.tipddyname = TIPDDY_MODULES.scorm;
      }
      /**
       *
       * @type { ASSIGN }
       * Feedback and handling dates if necesary
       *
       */
      if (newresource.modname === TIPDDY_MODULES.assign) {
        newresource.tipddyname = TIPDDY_MODULES.assign;

        if (newresource.dates.length > 0) {
          let open = newresource.dates.find(
            (d) => d.dataid === MOODLE_STRINGS.TIMEOPEN
          );
          let close = newresource.dates.find(
            (d) => d.dataid === MOODLE_STRINGS.TIMECLOSE
          );

          /**
           *
           * With the dates check for the active or inactive status
           *
           */
          if (open && close) {
            let today = new Date();
            let openDate = new Date(open.timestamp * 1000);
            let closeDate = new Date(close.timestamp * 1000);

            let isClose = closeDate < today;
            let isOpen = openDate < today && !isClose;

            newresource.isopen = isOpen;
            newresource.isclose = isClose;
            newresource.tipddyopen = open.timestamp;
            newresource.tipddyclose = close.timestamp;
          }
        }
      }
      /**
       *
       * @type { FOLDER }
       * Feedback and handling dates if necesary
       *
       */
      if (newresource.modname === TIPDDY_MODULES.folder) {
        newresource.tipddyname = TIPDDY_MODULES.folder;
      }

      /**
       *
       * @type { CHAT }
       * Feedback and handling dates if necesary
       *
       */
      if (newresource.modname === TIPDDY_MODULES.chat) {
        newresource.tipddyname = TIPDDY_MODULES.chat;
      }
      /**
       *
       * @type { PAGE }
       * Feedback and handling dates if necesary
       *
       */
      if (newresource.modname === TIPDDY_MODULES.page) {
        newresource.tipddyname = TIPDDY_MODULES.page;
      }
      /**schedule
       *
       * @type { PROGRAM }
       * Feedback and handling dates if necesary
       *
       */

      if (newresource.description?.toLowerCase().includes("programa")) {
        newresource.tipddytype = MOODLE_STRINGS.PROGRAMS;
        if (
          newresource.modname === TIPDDY_MODULES.url &&
          newresource.contents[0].fileurl.includes("vimeo")
        ) {
          newresource.tipddyname = TIPDDY_MODULES.vimeo;

          let vimeodata = newresource.contents[0].fileurl.split("/");
          newresource.video_id = vimeodata[vimeodata.length - 1];

          if (vimeodata.length > 4) {
            newresource.video_h = vimeodata[vimeodata.length - 1];
            newresource.video_id = vimeodata[vimeodata.length - 2];
          }
        }

        if (
          newresource.modname === TIPDDY_MODULES.resource &&
          newresource.contents.length > 0 &&
          newresource.contents[0].mimetype === MIME_TYPES.pdf
        ) {
          newresource.tipddyname = TIPDDY_MODULES.pdf;
        }

        programModules = [...programModules, newresource];
      }
      /**
       *
       * @type { SCHEDULE }
       * Feedback and handling dates if necesary
       *
       */
      if (
        newresource.description?.toLowerCase().includes(MOODLE_STRINGS.SCHEDULE)
      ) {
        newresource.tipddyname = TIPDDY_MODULES.schedule;
        newresource.tipddytype = TIPDDY_MODULES.schedule;
        newresource.tipddyurl = newresource.contents[0].fileurl.split("?")[0];
        newresource.course_id = course_id;
        schedule = [newresource];
      }

      /**
       *
       * @type { FEEDBACK:FINAL }
       * Feedback and handling dates if necesary
       *
       */
      if (newresource.description?.toLowerCase().includes("encuesta")) {
        newresource.tipddyname = TIPDDY_MODULES.finalfeedback;
      }
    }
  }

  let finalNormalizedModules =
    eliminateProgramAndOnlineAndScheduleModules(normalizeModules);

  return {
    modules: finalNormalizedModules,
    online: [
      {
        id: MOODLE_STRINGS.ONLINE,
        name: "Clases en linea",
        modules: onlineModules,
      },
    ],
    programs: [
      {
        id: MOODLE_STRINGS.PROGRAMS,
        name: "Programas",
        modules: programModules,
      },
    ],
    schedule,
  };
}

export function normalizeCategories({ courses, categories }) {
  /**
   * For each categorie find the courses that matches
   */
  let categoriesNeeded = categories.filter((c) => c.name !== "Cursos");
  for (const category of categoriesNeeded) {
    /**
     * Find the matching courses and pass it to the original category object
     */
    let matchingCourses = courses.filter((c) => c.category === category.id);
    /**
     * Setter
     */
    category.courses = matchingCourses;
  }
  /**
   * Returning the final modify original Object
   */

  return categoriesNeeded;
}

function eliminateProgramAndOnlineAndScheduleModules(modules) {
  let data = modules;
  for (let module of data) {
    let outputmodules = [];
    for (const resource of module.modules) {
      if (
        resource.tipddytype !== TIPDDY_MODULES.schedule &&
        resource.tipddytype !== MOODLE_STRINGS.PROGRAMS &&
        resource.tipddytype !== MOODLE_STRINGS.ONLINE
      ) {
        outputmodules = [...outputmodules, resource];
      }
    }
    module.modules = outputmodules;
  }

  return data;
}

function getRawProgress(module) {
  let total = module.modules.filter((task) => task.completiondata).length;
  if (total === 0) {
    module.hasProgress = false;
    return module;
  }

  let completed = module.modules
    .filter((task) => task.completiondata)
    .filter((task) => task.completiondata.state !== 0).length;

  let progressValue = Number((completed * 100) / total);

  module.hasProgress = true;
  module.rawProgress = Number(progressValue.toFixed(0));
  return module;
}

export function getCertificateAndFeedbackFromCourse({ modules }) {
  let feed = null;
  let cert = null;

  for (const module of modules) {
    for (let resource of module.modules) {
      if (resource.tipddyname === TIPDDY_MODULES.finalfeedback) {
        feed = resource;
      }

      if (resource.tipddyname === TIPDDY_MODULES.customcert) {
        cert = resource;
      }
    }
  }

  if (feed) {
    let isCompleted = feed.completiondata.state === 1;
    if (isCompleted) {
      feed = true;
    } else {
      feed = feed.url;
    }
  }

  if (cert) {
    cert = `${cert.url}&downloadown=1`;
  }

  return {
    feed,
    cert,
  };
}
