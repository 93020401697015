import React, { useEffect, useState } from "react";
import "./styles.css";
import { Layout } from "../../components/Layout";
import { useNavigate } from "react-router-dom";
// import { SNACKTYPES, useSnack } from "../../contexts/snack";
import ico_casilla_lapiz from "../../assets/images/ico-casilla-lapiz.png";
// import { fetchEraps } from "../../services/tipddy/dashboard";
import Skeleton from "react-loading-skeleton";
import { useMoodle } from "../../contexts/moodle";
import { ResultadosERAP } from "../../components/ResultadosERAP";

// let ev_array = [
//   {
//     name: "erap-ad",
//     id: 0,
//     progress: "0",
//     label: "Evaluacion RAP Alfabetización digital",
//   },
//   {
//     name: "erap-ce",
//     id: 1,
//     progress: "10",
//     label: "Evaluacion RAP Comunicación efectiva",
//   },
//   {
//     name: "erap-mlns",
//     id: 1,
//     progress: "100",
//     label: "Evaluacion RAP Marco legal y normativo de seguridad",
//   },
// ];

export const MisDiagnosticos = () => {
  const moodle = useMoodle();
  const navigate = useNavigate();
  // const [, handleSnack] = useSnack();
  const [evList, setEvList] = useState([]);
  const [evGrades, setEvGrades] = useState([]);
  const [evSelected, setEvSelected] = useState(null);
  const [viewResult, setViewResult] = useState(false);
  const [isLoading, setLoading] = useState(true);

  // const handleClickCompleted = () => {
  //   handleSnack({
  //     type: SNACKTYPES.success,
  //     payload: {
  //       message: "Esta evaluación RAP ya se encuentra realizada.",
  //     },
  //   });
  // };

  useEffect(() => {
    moodle.grades && console.log("grades:", moodle.grades);
    if (moodle.courses && moodle.grades) {
      let erap = [];
      let erap_ids = [];
      let erap_grades = [];

      erap = moodle.courses.filter((e) => e.shortname.includes("Evaluación"));
      erap.forEach((element) => {
        erap_ids.push(element.id);
      });
      setEvList(erap);
      erap_grades = moodle.grades.filter((g) => erap_ids.includes(g.courseid));
      setEvGrades(erap_grades);
      console.log({ erap, erap_ids, erap_grades });
      setLoading(false);
    }
  }, [moodle.grades, moodle.courses]);

  useEffect(() => {
    !moodle.courses && moodle.getCourses();
    !moodle.categories && moodle.getCategories();
    !moodle.grades && moodle.getGrades();

    // eslint-disable-next-line
  }, [moodle]);

  useEffect(() => {
    // eslint-disable-next-line
  }, [viewResult]);

  const handleNavigate = (id) => {
    return navigate(`/detalle-curso/${id}`);
  };

  const handleShowResult = (id) => {
    setEvSelected(id);
    setViewResult(true);
  };

  const handleExitResults = () => {
    setEvSelected(null);
    setViewResult(false);
  };

  if (viewResult) {
    return (
      <ResultadosERAP
        results={evGrades}
        id={evSelected}
        handleExitResults={handleExitResults}
      />
    );
  }

  // useEffect(() => {
  //   let new_array = [];
  //   moodle.courses &&
  //     fetchEraps().then((res) => {
  //       console.log(moodle.courses);
  //       for (let i = 0; i < moodle.courses.length; i++) {
  //         for (let j = 0; j < res.length; j++) {
  //           if (moodle.courses[i].id === res[j].id) {
  //             new_array.push(res[j]);
  //           }
  //         }
  //       }
  //       console.log({ new_array });
  //       setEvList(new_array);
  //       setLoading(false);
  //     });

  //   //eslint-disable-next-line
  // }, [moodle]);

  return (
    <Layout>
      <div
        className="diagnostic-box"
        style={{ minHeight: `${window.screen.height * 0.65}px` }}
      >
        <h1 className="ps-4 mis-d-tit">Evaluación de Aprendizajes Previos</h1>
        {!isLoading ? (
          <>
            {evList.length > 0 ? (
              evList.map((e, i) => (
                <div className="diagnostic-container" key={i}>
                  <div className="col-11">
                    <div className="header-erap">
                      <div className="left-header-erap">
                        <img
                          src={ico_casilla_lapiz}
                          alt="-"
                          style={{
                            width: "40px",
                            height: "auto",
                          }}
                        />
                        <h4 className="ev-tit">
                          {e.fullname.split("ERAP ")[1]}
                        </h4>
                      </div>
                      {e.progress === 0 ? (
                        <div
                          className="btn-diagnostic"
                          onClick={() => handleNavigate(e.id)}
                        >
                          Realizar Evaluación
                        </div>
                      ) : (
                        <div
                          className="btn-diagnostic completed"
                          onClick={() => handleShowResult(e.id)}
                        >
                          Evaluación Realizada
                        </div>
                      )}
                    </div>
                    <div className="linea-naranja"></div>
                  </div>
                </div>
              ))
            ) : (
              <div className="diagnostic-container">
                <div className="col-12">
                  <div className="header-erap-2">
                    <h4 className="ev-tit">
                      No tienes evaluaciones por realizar
                    </h4>
                  </div>
                  <div className="linea-naranja-2"></div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="diagnostic-container">
            <SkeletonErap />
            <SkeletonErap />
            <SkeletonErap />
            <SkeletonErap />
          </div>
        )}
      </div>
    </Layout>
  );
};

const SkeletonErap = () => {
  return (
    <Skeleton
      baseColor="#cccccc"
      style={{
        opacity: 0.5,
        width: "100%",
        height: "64px",
        marginBottom: "1rem",
      }}
    />
  );
};
