import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/auth";

export const PrivateRoute = ({ children }) => {
  const auth = useAuth();

  if (!auth.verify) return null;

  if (!auth.user) {
    return <Navigate to="/" />;
  }
  return children;
};
