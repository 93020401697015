import React from "react";

export const ProgressTitle = ({ title }) => {
  return (
    <h1
      className="historial"
      style={{ color: "#b2b2b2", marginBottom: "1rem" }}
    >
      {title}
    </h1>
  );
};
