import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import volver_flecha from "../../assets/images/volver-flecha.png";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../../components/AlertDialog";
import questionsAD from "./questionsAD.json";

export const InteriorDiagnostico = () => {
  const [evData, setEvData] = useState(null);
  const [data /* , setData */] = useState([questionsAD]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpen2 = () => setOpen2(true);

  const handleClose2 = () => setOpen2(false);

  const handleCancel = () => {
    navigate("/mis-diagnosticos");
  };

  const handleSend = () => {
    navigate("/mis-diagnosticos");
  };

  useEffect(() => {
    /* agregar logica de dato evaluacion */
    const ev_param = window.location.search.split("?")[1];
    setEvData(data[ev_param]);
    //eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <div className="go-back" onClick={handleOpen}>
        <img src={volver_flecha} alt="img-flecha-volver" />
        Volver
      </div>
      {evData ? (
        <div className="diagnostic-box">
          <h1 className="diagnostic-title mis-d-tit">{evData.title}</h1>
          {evData.questions.length > 0 &&
            evData.questions.map((e, i) => (
              <div className="diagnostic-container" key={i}>
                <div className="header-erap">
                  <h4>
                    {i + 1}. {e.title}
                  </h4>
                </div>
                <div className="linea-naranja-int"></div>
                {e.answer_type === "single" &&
                  e.options.map((element, i) => (
                    <div className="option-container" key={i}>
                      <input className="checkbox-ev" type="checkbox" />
                      <label className="label-ev">{element.title}</label>
                    </div>
                  ))}
              </div>
            ))}
          <div className="ev-buttons-container">
            <button
              type="button"
              className="ev-btn-cancel"
              onClick={handleOpen}
            >
              Cancelar
            </button>
            <button type="button" className="ev-btn-send" onClick={handleOpen2}>
              Enviar
            </button>
          </div>
        </div>
      ) : (
        /* falta agregar skeleton loader a futuro */
        <div> Cargando . . .</div>
      )}
      <AlertDialog
        open={open}
        title="¿Desea volver?"
        body={"se perderá el progreso de la evaluación"}
        handleClose={handleClose}
        handleConfirm={handleCancel}
        bgColor={"red"}
      />
      <AlertDialog
        open={open2}
        title="¿Desea enviar la evaluación?"
        body={"no podrá cambiar el resultado"}
        handleClose={handleClose2}
        handleConfirm={handleSend}
      />
    </Layout>
  );
};
