import { useContext, useReducer } from "react";
import { createContext } from "react";
import { COLORS } from "../utils/theme";

const INITIAL_SNACK = {
  visible: false,
  message: "",
  severity: "grey",
};

export const snackReducer = (state, { payload, type }) => {
  switch (type) {
    case SNACKTYPES.error:
      return {
        ...state,
        visible: true,
        message: payload.message,
        severity: COLORS.ERROR,
      };

    case SNACKTYPES.success:
      return {
        ...state,
        visible: true,
        message: payload.message,
        severity: COLORS.SUCCESS,
      };

    case SNACKTYPES.close:
      return {
        ...state,
        visible: false,
        message: "",
        severity: "grey",
      };

    default:
      return {
        ...state,
      };
  }
};

export const SNACKTYPES = {
  error: "error",
  success: "success",
  close: "close",
};

export const SnackContext = createContext(null);

export const SnackProvider = ({ children }) => {
  const [snack, handleSnack] = useReducer(snackReducer, INITIAL_SNACK);
  return (
    <SnackContext.Provider value={[snack, handleSnack]}>
      {children}
    </SnackContext.Provider>
  );
};

export const useSnack = () => useContext(SnackContext);
