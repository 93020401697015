import { setActivityProgressCompletedManually } from "../services/moodle/resources";
import {
  getCertificateAndFeedbackFromCourse,
  normalizeCategories,
  normalizer,
} from "../services/tipddy";
import { getMoodleCategories } from "../services/moodle/categories";
import { createContext, useContext, useState } from "react";
import { requestAutoLogin } from "../services/moodle/auth";
import { baseURL } from "../services/api";
import { useAuth } from "./auth";
import {
  getCoreCourseContent,
  getCourseUsersInfo,
  getOverviewGrades,
  getUserCourses,
  getUsers,
} from "../services/moodle/courses";
import { TIPDDY_MODULES, TIPDDY_URLS } from "../services/tipddy/state";
import {
  fetchNotifications,
  fetchNotificationsCount,
} from "../services/moodle/notifications";

const MoodleContext = createContext(null);

/* const TabsSelection = [
  {
    idx: 0,
    name: "contenido",
  },
  {
    idx: 1,
    name: "programa",
  },
  {
    idx: 2,
    name: "online",
  },
]; */

export const MoodleProvider = ({ children }) => {
  /**
   * Extracting user for cleaner code
   */
  const { user } = useAuth();

  /**
   * Global States for Moodle Context
   */
  const [categories, setCategories] = useState(null);

  const [selectedView, setSelectedView] = useState(0);

  const [onlineModules, setOnlineModules] = useState(null);

  const [programModules, setProgramModules] = useState(null);

  const [courses, setCourses] = useState(null);
  const [grades, setGrades] = useState(null);
  const [pageEmbed, setPageEmbed] = useState(null);
  const [currentModules, setCurrentModules] = useState(null);
  const [currentModuleId, setCurrentModuleId] = useState(null);
  const [currentResourceId, setCurrentResourceId] = useState(null);
  const [nextModuleId, setNextModuleId] = useState(null);
  const [nextResourceName, setNextResourceName] = useState(null);
  const [prevModuleId, setPrevModuleId] = useState(null);
  const [nextResourceId, setNextResourceId] = useState(null);
  const [prevResourceId, setPrevResourceId] = useState(null);
  const [isLastModule, setLastModule] = useState(null);
  const [, /* isFirstModule */ setFirstModule] = useState(null);
  const [isLastResource, setLastResource] = useState(null);
  const [isFirstResource, setFirstResource] = useState(null);
  const [currentCourseId, setCurrentCourseId] = useState(null);

  /**
   * Inital handler for different State CourseContent Getter is not Resolved at this stage
   * @param { Array } modules
   */
  function setInitialCurrentModules(modules, course_id) {
    /**
     * First load first Content and first module
     */
    setCurrentModuleId(modules[0].id);
    /**
     * Start de embed request with the first content
     */
    setInitalEmbed(modules, course_id).catch(console.log);
    /**
     * Start the navigation finder with the index of the content, at this moment the function for the modules getter
     * is not resolve so we pass the modules as well
     */
    setNavigationModules(modules[0], modules);
  }

  /**
   *
   * Handler for the navigation Buttons Service finding the next and prev Module handling the last and first exception
   * @param { Object } module
   * @param { Array } modules
   * @returns
   */
  function setNavigationModules(module, modules = null) {
    /**
     *
     * Checking if this is using at inital stage or during the different stages of the use of the app
     * where CurrentModules already exists
     */
    let MODULES = modules ? modules : currentModules;
    /**
     * Current Module Index
     */
    let currentIdx = MODULES.findIndex((m) => m.id === module.id);

    /**
     * Handling the last module exception
     */
    if (currentIdx === MODULES.length - 1) {
      setPrevModuleId(currentIdx - 1);
      setNextModuleId(null);
      setLastModule(true);
      return;
    }

    /**
     * Handling the first module exception
     */
    if (currentIdx === 0) {
      setNextModuleId(currentIdx + 1);
      setPrevModuleId(null);
      setFirstModule(true);
      setLastModule(false);
      return;
    }

    /**
     * Any module treatment
     */
    setNextModuleId(currentIdx + 1);
    setPrevModuleId(currentIdx - 1);
  }

  /*
   * Handler for the navigation Buttons Service finding the next and prev Resource handling the last and first exception
   * @param { Object } module
   * @param { Array } modules
   * @returns
   */
  function setNavigationResources({ resource, modules = null, module = null }) {
    let isFinishedCourse = false;

    /**
     * Validation for modules
     */
    let MODULES = modules ? modules : currentModules;

    /**
     * Validation for Current Module
     */
    let currentModule = module
      ? module
      : currentModuleId
      ? MODULES.find((m) => m.id === currentModuleId)
      : MODULES[0];

    /**
     * Initial Next module Index
     */
    let nextModuleIndex = MODULES.findIndex((m) => m.id === currentModule.id);

    /**
     * Initialize variables
     */
    let nextModuleName;
    let nextResourceName;

    /**
     * If there is a initial next module index, setting the next module name
     */
    if (nextModuleIndex !== MODULES.length - 1) {
      nextModuleName = MODULES[nextModuleIndex + 1].name;
    }

    /**
     * Current Modules
     */
    let INNER_MODULES = currentModule.modules;

    /**
     * Current Resource index
     */
    let resourceIdx = INNER_MODULES.findIndex((r) => r.id === resource.id);
    let moduleIdx = MODULES.findIndex((m) => m.id === module.id);

    if (moduleIdx === MODULES.length - 1) {
      isFinishedCourse = true;
    }

    /**
     * If current module only have 1 resource
     */
    if (INNER_MODULES.length === 1) {
      console.log({ isLastModule });
      setLastResource(true);
      setFirstResource(true);
      setPrevResourceId(null);
      setNextResourceId(null);
      isFinishedCourse
        ? setNextResourceName("Fin del curso")
        : setNextResourceName(`Siguiente Unidad: ${nextModuleName}`);

      return;
    }

    /**
     * Handling last resource
     */
    if (resourceIdx === INNER_MODULES.length - 1) {
      setPrevResourceId(resourceIdx - 1);
      setNextResourceId(null);
      setLastResource(true);
      setFirstResource(false);
      isFinishedCourse
        ? setNextResourceName("Fin del curso")
        : setNextResourceName(`Siguiente Unidad: ${nextModuleName}`);

      return;
    }

    /**
     * Handling first resource
     */
    if (resourceIdx === 0) {
      nextResourceName = currentModule.modules[resourceIdx + 1].name;
      setNextResourceId(resourceIdx + 1);
      setPrevResourceId(null);
      setFirstResource(true);
      setLastResource(false);
      setNextResourceName(nextResourceName);
      return;
    }

    /**
     * Updating resource name after logic
     */
    nextResourceName = currentModule.modules[resourceIdx + 1].name;

    /**
     * Result for the navigation states
     */
    setNextResourceName(nextResourceName);
    setFirstResource(false);
    setLastResource(false);
    setNextResourceId(resourceIdx + 1);
    setPrevResourceId(resourceIdx - 1);
  }

  /**
   *
   * @param { Array } modules
   * @returns
   */
  async function setInitalEmbed(modules, course_id) {
    /**
     * Loop into the modules to find the first valid module
     */
    let firstResource;
    for (const module of modules) {
      /**
       * At first encounter for module with modules
       */
      if (module.modules.length > 0) {
        /**
         * Request the embed authentication
         */
        firstResource = module.modules[0];
        /*         let key = await requestAutoLogin(user.username);
        let url = `${baseURL}auth/userkey/login.php?key=${key}&wantsurl=${firstResource.url}`;
 */
        actions.requestPageEmbed({
          course_id,
          pageurl: firstResource.url,
          module: module,
          resource: firstResource,
          modules,
        });

        return;
      }
    }
  }

  /**
   * Init the actions Object
   */
  let actions = {};

  /**
   * Handler for the nextModule Navigation
   */
  actions.navigateToNextModule = async function () {
    /**
     * At this point we already have or the logic and touchpoints to set the next module
     */
    let module = currentModules[nextModuleId];
    let resource = module.modules[0];
    let pageurl = resource.url;
    await actions.requestPageEmbed({ pageurl, module, resource });
    /**
     * Reset the navigation touchpoints
     */
    /*     setNavigationModules(module);
     */
  };

  actions.navigateToNextResource = async function () {
    let module = currentModules.find((m) => m.id === currentModuleId);
    let resource = module.modules[nextResourceId];
    let pageurl = resource.url;
    await actions.requestPageEmbed({ pageurl, module, resource });

    /*     setNavigationResources({ resource });
     */
  };

  /**
   * Handler for the prevModule Navigation
   */
  actions.navigateToPrevModule = async function () {
    /**
     * At this point we have all the logic and touch points to set the prev Module
     */
    let module = currentModules[prevModuleId];
    let resource = module.modules[module.modules.length - 1];
    let pageurl = resource.url;
    await actions.requestPageEmbed({ pageurl, module, resource });
    /**
     * Reset the navigation touchpoints
     */
    /*     setNavigationModules(module);
     */
  };

  actions.navigateToPrevResource = async function () {
    let module = currentModules.find((m) => m.id === currentModuleId);
    let resource = module.modules[prevResourceId];
    let pageurl = resource.url;
    await actions.requestPageEmbed({ pageurl, module, resource });

    /*     setNavigationResources({ resource });
     */
  };

  actions.navigateNext = async function () {
    if (isLastResource) {
      setLastResource(false);
      return await actions.navigateToNextModule();
    }

    return await actions.navigateToNextResource();
  };

  actions.navigatePrev = async function () {
    if (isFirstResource) {
      setFirstResource(false);

      return await actions.navigateToPrevModule();
    }
    return await actions.navigateToPrevResource();
  };

  /**
   * Handler for the userCourses Service
   * @returns
   */
  actions.getCourses = async () => {
    let data = await getUserCourses(user.token, user.id);
    setCourses(data);
    return data;
  };

  /**
   * Get categories and normalize matching courses
   * @returns
   */
  actions.getCategories = async () => {
    /**
     * Get courses
     */
    let courses = await getUserCourses(user.token, user.id);
    /**
     * Get categories
     */
    let tempcategories = await getMoodleCategories({ token: user.token });

    /**
     * Final Categories with normalize service
     */
    let categories = normalizeCategories({
      courses,
      categories: tempcategories,
    });

    /**
     * Set the state as standard in the context not in the component
     */
    setCategories(categories);
    return categories;
  };

  /**
   *
   * Handler for each course detail navigation
   * @param { Number } course_id
   * @returns
   */
  actions.getCourseContentByID = async (course_id, firstload = false) => {
    console.log({ firstload });
    let currentCourse;
    setCurrentCourseId(course_id);

    if (!courses) {
      let tempcourses = await actions.getCourses();
      currentCourse = tempcourses.filter((c) => c.id === Number(course_id))[0];
    }

    if (courses) {
      currentCourse = courses.filter((c) => c.id === Number(course_id))[0];
    }

    /**
     * Prepare the arguments for the service
     */

    let args = {
      token: user.token,
      id: user.id,
      course_id,
    };
    /**
     * Retrieve the data from the service
     */
    let data = await getCoreCourseContent(args);

    /**
     * Normalize the module with our Custom Normalizer
     */
    let { modules, online, programs } = normalizer(data);
    /**
     * Handler for the inital States
     */
    if (firstload) {
      setInitialCurrentModules(modules, course_id);
    }
    /**
     * Pass the current module for later
     */

    setOnlineModules(online);
    setCurrentModules(modules);
    setProgramModules(programs);
    return { modules, currentCourse };
  };

  /**
   *
   * Handler for the request Embed service
   * This handler receive all the content for the current module and resource
   * @param { String } pageurl
   * @param { Object } module
   * @param { Object } resource
   */
  actions.requestPageEmbed = async function ({
    pageurl,
    module,
    resource,
    modules = null,
    course_id = null,
  }) {
    try {
      let courseId = course_id ? course_id : currentCourseId;
      let url;

      /**
       * Request authentication
       */

      if (
        resource.tipddyname !== TIPDDY_MODULES.vimeo ||
        resource.tipddyname !== TIPDDY_MODULES.pdf
      ) {
        let key = await requestAutoLogin(user.username);
        url = `${baseURL}auth/userkey/login.php?key=${key}&wantsurl=${pageurl}`;
      }
      /**
       * Set the current States
       */
      setCurrentModuleId(module.id);
      setCurrentResourceId(resource.id);
      setNavigationModules(module, modules);
      setNavigationResources({ resource, module, modules });

      if (
        resource.completion !== 0 &&
        resource.completiondata.state === 0 &&
        currentCourseId
      ) {
        await actions.setResourceCompletedByVisibility(resource);
        setCurrentModules(null);
        await actions.getCourseContentByID(courseId, false);
      }
      /**
       * Set the state for the CoreVisualComponent
       */

      if (resource.tipddyname === TIPDDY_MODULES.vimeo) {
        url = `${TIPDDY_URLS.TIP_PLAYER}&video_id=${resource.video_id}&h=${resource.video_h}`;
      }
      if (resource.tipddyname === TIPDDY_MODULES.pdf) {
        url = `${resource.tipddyurl}?token=${user.token}`;
      }

      setPageEmbed(url);
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  };

  actions.getImgUrl = (item) =>
    `${item.overviewfiles[0].fileurl}?token=${user.token}`;

  actions.getScheduleByCategory = async (category_id) => {
    let categorySelected = categories.filter((c) => c.id === category_id)[0];
    let schedules = [];
    for (let course of categorySelected.courses) {
      let data = await getCoreCourseContent({
        token: user.token,
        course_id: course.id,
      });
      let { schedule } = normalizer(data, course.id);
      schedules = [...schedules, schedule];
    }
    return schedules;
  };

  actions.setResourceCompletedByVisibility = async (resource) => {
    const args = {
      token: user.token,
      completed: 1,
      cmid: resource.id,
    };
    await setActivityProgressCompletedManually(args);
    return Promise.resolve(await setActivityProgressCompletedManually(args));
  };

  actions.getGrades = async function () {
    let res = await getOverviewGrades(user.token);
    setGrades(res.grades);
    console.log(res.grades);
    return Promise.resolve(await getOverviewGrades(user.token));
  };

  actions.getProgress = async function (courses) {
    return Promise.resolve(await getOverviewGrades(user.token));
  };

  actions.getUsers = async function () {
    return Promise.resolve(await getUsers(user.token));
  };

  actions.fetchNotifications = async () => {
    return Promise.resolve(await fetchNotifications({ token: user.token }));
  };

  actions.fetchNotificationsCount = async () => {
    return Promise.resolve(
      await fetchNotificationsCount({ token: user.token })
    );
  };

  actions.contentSelected = async function () {
    setCurrentModules(null);
    setSelectedView(0);
    let { modules } = await actions.getCourseContentByID(currentCourseId);
    setCurrentModuleId(modules[0].id);
  };
  actions.programSelected = async function () {
    setCurrentModuleId(programModules[0].id);
    setCurrentModules(programModules);
    setSelectedView(1);
  };
  actions.onlineSelected = async function () {
    setCurrentModuleId(onlineModules[0].id);
    setCurrentModules(onlineModules);
    setSelectedView(2);
  };

  actions.resetView = function () {
    setSelectedView(0);
    setCurrentModuleId(null);
    setCurrentModules(null);
    setPageEmbed(null);
  };

  actions.getProgressDetail = async function ({ course }) {
    let args = {
      token: user.token,
      id: user.id,
      course_id: course.id,
    };
    /**
     * Retrieve the data from the service
     */
    let data = await getCoreCourseContent(args);

    /**
     * Normalize the module with our Custom Normalizer
     */
    let { modules } = normalizer(data);

    let { cert, feed } = getCertificateAndFeedbackFromCourse({ modules });

    return {
      cert,
      feed,
    };
  };

  actions.getCourseUserList = async function (id) {
    let args = {
      token: user.token,
      course_id: id,
    };

    let data = await getCourseUsersInfo(args);
    // console.log("getCourseUserInfo:", data);
    if (data.groups.length === 0) {
      return null;
    }
    // let user_data = data.filter((e) => e.id === user.id);
    // console.log("core_enrol_get_enrolled_users:", user_data);

    return data.groups[0].name;
  };

  /**
   *
   * Exports values with states and handlers
   */
  let values = {
    grades,
    courses,
    categories,
    pageEmbed,
    currentModules,
    currentModuleId,
    currentResourceId,
    nextResourceName,
    selectedView,
    setCurrentModuleId,
    setPageEmbed,
    setCategories,
    setCourses,
    navigateNext: actions.navigateNext,
    navigatePrev: actions.navigatePrev,
    navigateToPrevResource: actions.navigateToPrevResource,
    navigateToNextResource: actions.navigateToNextResource,
    navigateToPrevModule: actions.navigateToPrevModule,
    navigateToNextModule: actions.navigateToNextModule,
    getCategories: actions.getCategories,
    getCourses: actions.getCourses,
    getCourseContentByID: actions.getCourseContentByID,
    requestPageEmbed: actions.requestPageEmbed,
    getGrades: actions.getGrades,
    getProgress: actions.getProgress,
    getCourseImg: actions.getImgUrl,
    fetchNotifications: actions.fetchNotifications,
    fetchNotificationsCount: actions.fetchNotificationsCount,
    contentSelected: actions.contentSelected,
    programSelected: actions.programSelected,
    onlineSelected: actions.onlineSelected,
    getProgressDetail: actions.getProgressDetail,
    test: actions.getUsers,
    getScheduleByCategory: actions.getScheduleByCategory,
    resetView: actions.resetView,
    getCourseUserList: actions.getCourseUserList,
  };

  return (
    <MoodleContext.Provider value={values}>{children}</MoodleContext.Provider>
  );
};

export const useMoodle = () => useContext(MoodleContext);
