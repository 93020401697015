import React, { useEffect, useState } from "react";
import "./styles.css";
import { Layout } from "../../components/Layout";
import BasicTabs from "../../components/Tabs";
import { useMoodle } from "../../contexts/moodle";
import { ProgressTitle } from "../Progress/ProgressTitle";
import { useAuth } from "../../contexts/auth";
import Skeleton from "react-loading-skeleton";

export const MiHorario = () => {
  const [viewselected, setViewSelected] = useState(0);
  const [schedules, setSchedules] = useState([]);
  const [loading, setLoading] = useState(true);
  const auth = useAuth();
  const moodle = useMoodle();

  // useEffect(() => {
  //   console.log(viewselected);
  // }, [viewselected]);

  useEffect(() => {
    !moodle.categories && moodle.getCategories();
    // eslint-disable-next-line
  }, [moodle.categories]);

  useEffect(() => {
    if (moodle.categories) {
      setLoading(true);
      moodle
        .getScheduleByCategory(moodle.categories[viewselected].id)
        .then((res) => {
          setSchedules(res);
          setLoading(false);
        })
        .catch((e) => console.error(e));
    }
    // eslint-disable-next-line
  }, [moodle.categories, viewselected]);

  return (
    <Layout>
      <div className="horario-box">
        <ProgressTitle title="Mi Horario" />
        <div className="tabs-horario-container">
          <BasicTabs
            tabs={moodle.categories}
            viewselected={viewselected}
            setViewSelected={setViewSelected}
          />
          <div className="row calendar-row">
            {loading ? (
              <CardScheduleRowSkeleton />
            ) : (
              schedules.map((e, i) => (
                <div className="col-md-6 col-xl-3" key={i}>
                  <PreviewImage
                    courseName={
                      moodle.categories
                        ? moodle.categories[viewselected].courses[i].shortname
                        : ""
                    }
                    href={e}
                    auth={auth}
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const PreviewImage = ({ href = "", courseName = "a", auth }) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <h5
        className="text-center"
        style={{ color: "#b2b2b2", marginBottom: "1rem", height: "110px" }}
      >
        {courseName}
      </h5>
      <a
        className="sitio-web"
        href={
          href[0]?.tipddyurl
            ? `${href[0]?.tipddyurl}?token=${auth.user.token}`
            : null
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={require("../../assets/images/calendario-v1.png")}
          alt="foto horario"
          className="img-horario"
        />
        {/* <iframe
          src={`${href[0]?.tipddyurl}?token=${auth.user.token}#toolbar=0`}
          id="video-player"
          width="100%"
          allow="autoplay"
          height={"100%"}
          title="video"
          scroll="false"
          seamless="seamless"
        /> */}
      </a>
    </div>
  );
};

const CardScheduleRowSkeleton = () => {
  let array = ["", "", "", ""];
  return (
    <>
      {array.map((e, i) => (
        <div className="col-md-6 col-xl-3" key={i}>
          <Skeleton
            baseColor="#cccccc"
            style={{
              opacity: 0.5,
              width: "100%",
              height: "110px",
              marginBottom: "1rem",
            }}
          />
          <Skeleton
            baseColor="#cccccc"
            style={{
              opacity: 0.5,
              width: "100%",
              borderRadius: "5px",
              height: "250px",
              marginBottom: "1rem",
            }}
          />
        </div>
      ))}
    </>
  );
};
