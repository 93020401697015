import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SNACKTYPES, useSnack } from "../../contexts/snack";
import "./styles.css";

export const CoursePreviewRow = ({
  title,
  sub_title,
  courses,
  img,
  erapCompleted,
}) => {
  const [, handleSnack] = useSnack();
  const navigate = useNavigate();
  const [currentProgress, setProgress] = useState(0);

  const handleNavigate = (id, index) => {
    if (index <= currentProgress) {
      navigate(`/detalle-curso/${id}`);
    } else {
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: "Debes completar el modulo anterior para tener acceso",
        },
      });
    }
  };

  React.useEffect(() => {
    if (erapCompleted) {
      courses.map((element, idx) => {
        if (element.course_id === "Incompleto") {
          setProgress(idx);
        }
        return null;
      });
    } else {
      setProgress(-1);
    }

    //eslint-disable-next-line
  }, []);

  const checkCourses = (cursos) => {
    let status = false;

    for (let i = 0; i < cursos.length; i++) {
      if (cursos[i].name !== undefined) {
        status = true;
      }
    }
    return status;
  };

  if (!checkCourses(courses)) {
    return <></>;
  }

  return (
    <div className="course-preview-container">
      <p className="tit-course-preview">{title}</p>
      <p className="subtit-course-preview">{sub_title}</p>
      <div className="row mt-3 esp-listado-cel">
        {courses.map((element, idx) => {
          if (img[idx]) {
            return (
              <div
                className="col-sm-6 col-lg-3"
                onClick={() => handleNavigate(element.course_id, idx)}
                style={{ opacity: idx > currentProgress ? "0.3" : "1" }}
                key={idx}
              >
                <img
                  src={`${img[idx]}`}
                  alt="foto curso"
                  className="img-curso"
                />
              </div>
            );
          } else {
            return <div key={idx}></div>;
          }
        })}
      </div>
    </div>
  );
};
