import React, { useEffect, useState } from "react";
import { Layout } from "../Layout";
import check from "../../assets/images/tic.png";
import "./styles.css";
// import { useNavigate } from "react-router-dom";

export const ResultadosERAP = ({ results, id, handleExitResults }) => {
  // const navigate = useNavigate();
  const [data, setData] = useState(null);

  // useEffect(() => {
  //   if (!results && !results.approved) {
  //     navigate("/mis-diagnosticos");
  //     return;
  //   }
  //   let per =
  //     (Number(results.correctAnswers) / Number(results.totalAnswers)) * 100;
  //   setPercentage(per);
  //   console.log(per);
  //   //eslint-disable-next-line
  // }, []);

  const parseEvData = (results) => {
    console.log("parseEvData: ", results);
    let fetchData = results.filter((e) => e.courseid === id)[0];
    fetchData.percentage = fetchData.grade.split(",")[0];
    if (Number(fetchData.percentage) >= 85) {
      fetchData.approved = true;
    } else {
      fetchData.approved = false;
    }
    fetchData.correctAnswers = (20 * Number(fetchData.percentage)) / 100;
    console.log({ fetchData });
    setData(fetchData);
  };

  useEffect(() => {
    console.log({ results });
    if (!data) {
      parseEvData(results);
    }
    //eslint-disable-next-line
  }, [data]);

  return (
    <>
      {data && (
        <Layout>
          <div className="ev-header-ins">
            <h1 className="ps-4 mis-d-tit text-white text-center pb-0">
              Resultados <br />
              {/* Evaluación de Reconocimiento de Aprendizajes Previos */}
            </h1>
          </div>
          <div
            className="diagnostic-box"
            style={{ minHeight: `${window.screen.height * 0}px` }}
          >
            <div className="intro-box">
              <div
                className="result-card row"
                style={{
                  backgroundColor: data.approved ? "#2f580e" : "#f8333c",
                  //       ? "#006b95"
                  //       : "#ff7000",,
                }}
              >
                <div className="col-lg-4 per">{data.percentage}%</div>
                <div className="col-lg-8 res-data">
                  <p>
                    <span>Preguntas totales: 20</span>
                  </p>
                  <p>
                    <span>Preguntas correctas: </span>
                    {data.correctAnswers}
                  </p>
                  <p>
                    <span>Estado Evaluación: </span>
                    {data.approved ? "Aprobado" : "Reprobado"}
                  </p>
                </div>
              </div>
            </div>
            {data.approved ? (
              <div className="intro-box">
                <h4 className="intro-text">
                  ¡Felicidades! Queremos agradecerte y felicitarte por haber
                  aprobado con éxito la Evaluación de Aprendizajes Previos en
                  Merkademia.
                </h4>
                <p className="intro-text">
                  <img
                    src={check}
                    alt="check icon"
                    className="intro-dot-icon"
                  />
                  Tu dedicación y esfuerzo han dado frutos, y nos complace
                  informarte que has quedado en el Nivel 2.
                </p>
                <p className="intro-text">
                  <img
                    src={check}
                    alt="check icon"
                    className="intro-dot-icon"
                  />
                  Tu desempeño en la evaluación demuestra tu compromiso con el
                  aprendizaje y tu interés en superarte constantemente. Estamos
                  emocionados de acompañarte en esta aventura educativa y
                  ayudarte a seguir creciendo en tu camino académico.
                </p>
                <p className="intro-text">
                  <img
                    src={check}
                    alt="check icon"
                    className="intro-dot-icon"
                  />
                  Recuerda que cada logro es un paso hacia adelante y una
                  oportunidad para alcanzar metas aún mayores. ¡Sigue
                  persiguiendo tus sueños y alcanzarás la grandeza!
                </p>
                <p className="intro-text intro-atte">Saludos Lila y Stock</p>
              </div>
            ) : (
              <div className="intro-box">
                <h4 className="intro-text">
                  Queremos agradecerte por haber realizado la Evaluación de
                  Aprendizajes Previos en Merkademia.
                </h4>
                <p className="intro-text">
                  <img
                    src={check}
                    alt="check icon"
                    className="intro-dot-icon"
                  />
                  Tu dedicación para completarla es muy apreciada y nos complace
                  informarte que has quedado en el Nivel 1.
                </p>
                <p className="intro-text">
                  <img
                    src={check}
                    alt="check icon"
                    className="intro-dot-icon"
                  />
                  No te preocupes, este desafío será una valiosa oportunidad
                  para seguir desarrollando tus habilidades y conocimientos en
                  el área laboral que te interesa. Estamos seguros de que
                  demostrarás todo tu potencial y aprovecharás esta experiencia
                  al máximo.
                </p>
                <p className="intro-text">
                  <img
                    src={check}
                    alt="check icon"
                    className="intro-dot-icon"
                  />
                  Recuerda siempre que cada paso hacia adelante te acerca más a
                  tus metas. ¡Sigue adelante con entusiasmo y determinación!
                  Estamos seguros de que alcanzarás el éxito en cada nuevo reto
                  que enfrentes.
                </p>
                <p className="intro-text">
                  <img
                    src={check}
                    alt="check icon"
                    className="intro-dot-icon"
                  />
                  Estamos emocionados de ver todo lo que lograrás en el futuro.
                </p>
                <p className="intro-text intro-atte">Saludos Lila y Stock</p>
              </div>
            )}
            <div className="ev-buttons-container2">
              <button
                type="button"
                className="ev-btn-send ev-btn-next"
                onClick={() => handleExitResults()}
              >
                Volver a mis diagnosticos
              </button>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};
