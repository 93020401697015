import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";
import { useMoodle } from "../../contexts/moodle";

import cert from "../../assets/images/school-certificate.png";

export default function BasicTable({ headers, courses, grades }) {
  return (
    <TableContainer
      component={Paper}
      sx={{ backgroundColor: "#212735", color: "#fff" }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers &&
              headers.map((h, idx) => {
                return (
                  <TableCell
                    key={idx}
                    scope="col"
                    sx={{
                      color: "#b2b2b2",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      textAlign: idx === 4 || idx === 5 ? "center" : "unset",
                    }}
                  >
                    {h}
                  </TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody>
          {courses &&
            grades &&
            courses.map((row, idx) => {
              let grade = grades.filter((g) => g.courseid === row.id)[0].grade;

              return <RowItem grade={grade} course={row} key={idx} />;
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const RowItem = ({ course, grade }) => {
  const moodle = useMoodle();
  const navigation = useNavigate();

  const [feedback, setFeedback] = React.useState(null);

  const [certificate, setCertificate] = React.useState(null);

  const [data, setData] = React.useState({
    name: course.displayname,
    start: new Date(course.startdate * 1000).toLocaleDateString(),
    end:
      course.enddate !== 0
        ? new Date(course.enddate * 1000).toLocaleDateString()
        : "Sin fecha de termino",
  });

  React.useEffect(() => {
    setData({
      name: course.displayname,
      start: new Date(course.startdate * 1000).toLocaleDateString(),
      end:
        course.enddate !== 0
          ? new Date(course.enddate * 1000).toLocaleDateString()
          : "Sin fecha de termino",
    });

    moodle
      .getProgressDetail({ course })
      .then(({ feed, cert }) => {
        // console.log(feed);
        console.log(cert);

        setFeedback(feed);
        setCertificate(cert);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line
  }, [course]);

  const handleDetailNavigation = () =>
    navigation(`/listcourses/progress/${course.id}`, {
      state: { name: data.name },
    });

  const handleFeedbackNavigation = () => {
    navigation("/moodle-web-view", {
      state: { url: feedback },
    });
  };

  const handleOpenCertificate = () => {
    if (feedback === true) {
      window.open(certificate);
    }
  };

  return (
    <>
      <TableRow
        key={data.name}
        sx={{
          "&:last-child td, &:last-child th": { color: "#b2b2b2" },
        }}
      >
        <TableCell
          component="th"
          scope="row"
          onClick={handleDetailNavigation}
          sx={{ color: "#b2b2b2", width: "35%" }}
        >
          {data.name}
        </TableCell>
        <TableCell align="left" sx={{ color: "#b2b2b2" }}>
          {grade}
        </TableCell>
        <TableCell align="left" sx={{ color: "#b2b2b2" }}>
          {data.start}
        </TableCell>
        <TableCell align="left" sx={{ color: "#b2b2b2" }}>
          {data.end}
        </TableCell>
        <TableCell sx={{ color: "#b2b2b2", textAlign: "center" }}>
          {feedback === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <DoneIcon sx={{ color: "#16a34a", fontSize: 30 }} />
            </div>
          ) : (
            <div
              onClick={handleFeedbackNavigation}
              style={{
                backgroundColor: "#fff",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                borderRadius: 5,
                padding: "2px 15px",
                margin: "0 auto",
                cursor: "pointer",
              }}
            >
              <p style={{ color: "#000" }}>Realizar encuesta</p>
            </div>
          )}
        </TableCell>
        <TableCell
          align="left"
          sx={{ color: "#b2b2b2", cursor: "pointer" }}
          onClick={handleOpenCertificate}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={cert}
              width={70}
              style={{ opacity: feedback === true ? 1 : 0.6 }}
              alt="..."
            />
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};
