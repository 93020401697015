import { CanalesAtencion } from "../pages/CanalesAtencion";
import { DetalleCurso } from "../pages/DetalleCurso";
import { InteriorDiagnostico } from "../pages/InteriorDiagnostico";
import { InteriorDiagnostico2 } from "../pages/InteriorDiagnostico2";
import { Login } from "../pages/Login";
import { MiHorario } from "../pages/MiHorario";
import { MiMalla } from "../pages/MiMalla";
import { MiPerfil } from "../pages/MiPerfil";
import { MisCursos } from "../pages/MisCursos";
import { MisDiagnosticos } from "../pages/MisDiagnosticos";
import { MoodleWebView } from "../pages/MoodleWebView";
import { PerfilOcupacional } from "../pages/PerfilOcupacional";
import Progress from "../pages/Progress/beta";
import { QueEsMerkademia } from "../pages/QueEsMerkademia";
import { Reglamentos } from "../pages/Reglamentos";
import { SenceErrorLogin } from "../pages/Sence/error";
import { SenceLogin } from "../pages/Sence/login";
import { SenceLogout } from "../pages/Sence/logout";

export const routes = [
  {
    path: "/",
    element: Login,
    requireAuth: false,
  },
  {
    path: "/mis-cursos",
    element: MisCursos,
    requireAuth: true,
  },
  {
    path: "/avance-academia",
    element: Progress,
    requireAuth: true,
  },
  {
    path: "/mis-diagnosticos",
    element: MisDiagnosticos,
    requireAuth: true,
  },
  {
    path: "/canales-atencion",
    element: CanalesAtencion,
    requireAuth: true,
  },
  {
    path: "/mi-malla",
    element: MiMalla,
    requireAuth: true,
  },
  {
    path: "/mi-horario",
    element: MiHorario,
    requireAuth: true,
  },
  {
    path: "/perfil-ocupacional",
    element: PerfilOcupacional,
    requireAuth: true,
  },
  {
    path: "/reglamentos",
    element: Reglamentos,
    requireAuth: true,
  },
  {
    path: "/mi-perfil",
    element: MiPerfil,
    requireAuth: true,
  },
  {
    path: "/detalle-curso/:id",
    element: DetalleCurso,
    requireAuth: true,
  },
  {
    path: "/moodle-web-view",
    element: MoodleWebView,
    requireAuth: true,
  },
  {
    path: "/auth/sence/login",
    element: SenceLogin,
    requireAuth: true,
  },
  {
    path: "/auth/sence/logout",
    element: SenceLogout,
    requireAuth: true,
  },
  {
    path: "/auth/sence/login/error",
    element: SenceErrorLogin,
    requireAuth: true,
  },
  {
    path: "/auth/sence/logout/error",
    element: SenceErrorLogin,
    requireAuth: true,
  },
  {
    path: "/que-es-merkademia",
    element: QueEsMerkademia,
    requireAuth: true,
  },
  {
    path: "/interior-diagnostico",
    element: InteriorDiagnostico,
    requireAuth: true,
  },
  {
    path: "/interior-diagnostico2",
    element: InteriorDiagnostico2,
    requireAuth: true,
  },
];
