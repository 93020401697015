import React from "react";

export const HeaderProgress = () => {
  return (
    <>
      <div style={{ paddingTop: 100 }}></div>
      <h1 className="historial" style={{ color: "#b2b2b2" }}>
        Progreso
      </h1>
      <ul className="progress-list mt-4 mb-5">
        <li className="progress-list" style={{ color: "#b2b2b2" }}>
          <div className="square" style={{ backgroundColor: "#006b95" }}></div>
          &nbsp;Completado
        </li>
        <li className="progress-list" style={{ color: "#b2b2b2" }}>
          <div className="square" style={{ backgroundColor: "#ff9c00" }}></div>
          &nbsp;Pendiente
        </li>
      </ul>
    </>
  );
};
