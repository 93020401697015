export const DEFAULT_LOGIN = {
  username: "",
  password: "",
};

export const DEFAULT_PROFILE = {
  fullname: "",
  idnumber: "",
  institution: "",
  position: "",
  address: "",
  phone1: "",
  phone2: "",
  email: "",
};

/* VALORES DE SENCE */
export const LIMIT_CRON_SENCE = 9000;
export const LIMIT_CRON_AVG_SENCE = 8990;
export const LIMIT_CRON_DIALOG_SENCE = 8880;

/* VALORES DE EVALUACION RECONOCIMIENTO APRENDIZAJES PREVIOS */
export const LIMIT_CRON_ERAP = 2700;
export const LIMIT_CRON_AVG_ERAP = 2690;
export const LIMIT_CRON_DIALOG_ERAP = 2680;
