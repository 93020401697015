import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { Layout } from "../../components/Layout";
import { useMoodle } from "../../contexts/moodle";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { CourseContentModuleRowComponent } from "../../components/ModuleContentRowComponent";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { CustomSpinner } from "../../components/Loader";
import {
  initSenceApplication,
  verifyCodSence,
  verifyRegistration,
  verifySenceSession,
} from "../Sence/functions";
import { useAuth } from "../../contexts/auth";
import groupIcon from "../../assets/images/grupo.png";
import { SNACKTYPES, useSnack } from "../../contexts/snack";

export const DetalleCurso = () => {
  const auth = useAuth();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [, setModules] = useState(null);
  const [isEV, setEV] = useState(null);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [group, setGroup] = useState(null);
  const contentRef = useRef(null);
  const moodle = useMoodle();
  const navigate = useNavigate();
  const [, handleSnack] = useSnack();

  // if (!params.id) {
  //   return navigate("/mis-cursos");
  // }

  useEffect(() => {
    if (params.id === "undefined" || params.id === "null" || !params.id) {
      handleSnack({
        type: SNACKTYPES.error,
        payload: {
          message: `No está matriculado en el curso, por favor contactar mesa de ayuda.`,
        },
      });
      navigate("/mis-cursos");
    } else {
      moodle
        .getCourseContentByID(params.id, true)
        .then(({ currentCourse, modules }) => {
          setModules(modules);
          setCurrentCourse(currentCourse);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      moodle.resetView();
    };

    /*     return () => {
      moodle.getCourseContentByID(courseID).catch((err) => {
        console.log(err);
      });
    }; */
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      params.id === "34" ||
      params.id === "35" ||
      params.id === "36" ||
      params.id === "37"
    ) {
      setEV(true);
      console.log("ev true");
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      moodle.getCourseUserList(params.id).then((res) => {
        console.log("group: ", res);
        setGroup(res);
      });
    }
    //eslint-disable-next-line
  }, [params.id]);

  /**
   * Initialize sence validation
   */
  useEffect(() => {
    /**
     * If the current course data exist, start validation, else return
     */
    if (currentCourse) {
      initSenceValidation({ currentCourse, auth });
    }
    // eslint-disable-next-line
  }, [currentCourse]);

  /**
   *
   * This is the only logic for this component, can also be modify in later versions
   */
  const initSenceValidation = async ({ currentCourse, auth }) => {
    /***
     *
     * Verify if the course has de id number with codSence
     */
    let { isSence, idSence } = verifyCodSence({
      currentCourse,
      user_id: auth.user.id,
    });

    if (!isSence) {
      setLoading(false);
      return;
    }

    /**
     * Verify if there is and active sence session in the app
     */
    let { isActive } = verifySenceSession();

    if (isActive) {
      setLoading(false);
      return;
    }

    /**
     *
     * Verify if the user is registered in the sence and moodle NRU
     */
    let isRegistered = await verifyRegistration(params.id, auth.user.id);
    if (!isRegistered) {
      setLoading(false);
      return;
    }
    /**
     *
     * Start the sence flow for the application
     */
    if (isSence && isRegistered) {
      initSenceApplication({
        idSence,
        auth,
        session: null,
        isLogin: true,
        codCourse: isRegistered,
        course_id: params.id,
      });
    }
  };

  useEffect(() => {
    console.log(moodle.currentModules);
  }, [moodle.currentModules]);

  if (loading) {
    return <CustomSpinner />;
  }

  return (
    <Layout footer={false} helpPopUp="detailcourse">
      <DetailCourseWrapper>
        {!isEV ? (
          <>
            <CoreVisualContent
              moodle={moodle}
              course_id={window.location.search.split("?")[1]}
              token={auth.user.token}
            />
            <CourseContentContainer>
              {/*        <DiagnosticEvaluationButton /> */}
              <TabsRow />
              {group && <GroupRow group={group} />}
              <ContentPickerContainer container={contentRef}>
                {moodle.currentModules &&
                  moodle.currentModules.map((module, idx) => {
                    return (
                      <CourseContentModuleRowComponent
                        container={contentRef}
                        module={module}
                        key={module.id}
                        moodle={moodle}
                        idx={idx}
                      />
                    );
                  })}
                {!moodle.currentModules && <SkeletonModules />}
              </ContentPickerContainer>
            </CourseContentContainer>
          </>
        ) : (
          <CoreVisualContent
            moodle={moodle}
            course_id={window.location.search.split("?")[1]}
            token={auth.user.token}
            isEV={isEV}
          />
        )}
      </DetailCourseWrapper>
    </Layout>
  );
};

const SkeletonModules = () => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((k, i) => {
        return <SkeletonModule key={i} />;
      })}
    </>
  );
};

const SkeletonModule = () => {
  return (
    <div
      style={{ display: "flex", padding: "1.2rem 2rem", alignItems: "center" }}
    >
      <Skeleton
        baseColor="#adb5bd"
        style={{
          opacity: 0.1,
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          marginRight: "1rem",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "350px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "320px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
        <Skeleton
          baseColor="#adb5bd"
          style={{
            opacity: 0.1,
            width: "220px",
            height: "12px",
            marginLeft: "5px",
            borderRadius: "10px",
          }}
        />
      </div>
    </div>
  );
};

const DetailCourseWrapper = ({ children }) => {
  return (
    <div className="detail-course-container">
      <div className="row mx-0 " style={{ height: "100% " }}>
        {children}
      </div>
    </div>
  );
};

const CoreVisualContent = ({ moodle, course_id, token, isEV = false }) => {
  let embed = moodle.pageEmbed;
  const auth = useAuth();
  const frame = React.useRef(null);

  React.useEffect(() => {
    console.log({ isEV });
    let refreshTrigger = localStorage.getItem("refresh");
    let newaccess = Number(localStorage.getItem("newaccess"));
    if (!refreshTrigger && newaccess) {
      let last = newaccess;
      let new_ = auth.user.id;
      let refresh = last === new_ ? false : true;
      console.log("refrescar contenido: ", refresh);
      if (refresh) {
        frame.current.addEventListener("load", () => {
          let url = window.location.pathname;
          localStorage.setItem("refresh", true);
          localStorage.removeItem("newaccess");
          window.location.replace(`${url}?watch=${new Date().getTime()}`);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Inicio Image Embed code */

  const handleImgToken = (html, token) => {
    // Crear un elemento div para cargar el HTML como texto
    let src_token_with_param = `?token=${token}`;
    let src_token_without_param = `&token=${token}`;
    let div = document.createElement("div");
    div.innerHTML = html;

    // Obtener todas las etiquetas de imagen
    let imagenes = div.getElementsByTagName("img");

    // Recorrer todas las etiquetas de imagen y agregar el token al final del atributo src
    for (let i = 0; i < imagenes.length; i++) {
      let src = imagenes[i].getAttribute("src");
      if (src.includes("?")) {
        imagenes[i].setAttribute("src", src + src_token_without_param);
      } else {
        imagenes[i].setAttribute("src", src + src_token_with_param);
      }
    }

    // Devolver el HTML modificado
    return div.innerHTML;
  };

  if (embed && embed.includes("<img")) {
    let new_embed = handleImgToken(embed, token);

    return (
      <div
        className={"col-lg-8"}
        style={{
          position: "relative",
          minHeight: "90vh",
          height: "100%",
        }}
      >
        <div
          style={{
            maxHeight: "100%",
            width: "100%",
            overflow: "scroll",
          }}
          dangerouslySetInnerHTML={{ __html: new_embed }}
        ></div>
        {moodle.selectedView === 0 && <NavigationButtons />}
      </div>
    );
  }

  if (embed && !embed.startsWith("http")) {
    return (
      <div
        className={"col-lg-8"}
        style={{
          position: "relative",
          minHeight: "90vh",
        }}
      >
        <div
          style={{
            maxHeight: "100%",
            width: "100%",
            overflow: "scroll",
          }}
          dangerouslySetInnerHTML={{ __html: embed }}
        ></div>
        {moodle.selectedView === 0 && <NavigationButtons />}
      </div>
    );
  }

  return (
    <div
      className={isEV ? "col-lg-12" : "col-lg-8"}
      style={{
        position: "relative",
        minHeight: "90vh",
      }}
    >
      <iframe
        ref={frame}
        src={embed}
        id="video-player"
        frameBorder="0"
        width="100%"
        allow="autoplay"
        height={"100%"}
        title="video"
      />

      {moodle.selectedView === 0 && !isEV && <NavigationButtons />}
    </div>
  );
};

const CourseContentContainer = ({ children }) => {
  return (
    <div
      className="col-lg-4"
      style={{
        height: "100%",
        padding: "0 .5rem ",
        borderLeft: "1px solid grey",
        marginBottom: window.screen.width < 1000 && "100px",
      }}
    >
      {children}
    </div>
  );
};

/* const DiagnosticEvaluationButton = () => {
  return (
    <button
      type="button"
      className="btn btn-primary btn-lg btn-block btn-evaluacion"
    >
      Realizar Evaluación Diagnóstica
    </button>
  );
}; */

const TabsRow = () => {
  const moodle = useMoodle();

  return (
    <div className="row mx-0 tabs-row">
      <button
        type="button"
        className="col-md-4 btn btn-primary btn-tab-course"
        onClick={() => moodle.contentSelected()}
        style={{
          backgroundColor: moodle.selectedView === 0 ? "#69b7cb" : "#15171f",
          color: moodle.selectedView === 0 ? "#fff" : "#b2b2b2",
        }}
      >
        Contenido
      </button>
      <button
        type="button"
        className="col-md-4 btn btn-primary btn-tab-course"
        onClick={() => moodle.programSelected()}
        style={{
          backgroundColor: moodle.selectedView === 1 ? "#69b7cb" : "#15171f",
          color: moodle.selectedView === 1 ? "#fff" : "#b2b2b2",
        }}
      >
        Mi Programa
      </button>
      <button
        type="button"
        className="col-md-4 btn btn-primary btn-tab-course"
        onClick={() => moodle.onlineSelected()}
        style={{
          backgroundColor: moodle.selectedView === 2 ? "#69b7cb" : "#15171f",
          color: moodle.selectedView === 2 ? "#fff" : "#b2b2b2",
        }}
      >
        Clases en línea
      </button>
    </div>
  );
};
const GroupRow = ({ group }) => {
  return (
    <div className="row mx-0 group-row">
      <div className="group-text">
        <img className="group-icon" src={groupIcon} alt="group icon" />
        Grupo: {group}
      </div>
    </div>
  );
};

const ContentPickerContainer = ({ children, container }) => {
  return (
    <div
      ref={container}
      className="content-container"
      style={{
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
        overflowY: "scroll",
        maxHeight: `${window.innerHeight - 250.68}px`,
      }}
    >
      {children}
    </div>
  );
};

const NavigationButtons = () => {
  const moodle = useMoodle();

  const styles = {};

  styles.container =
    window.screen.width > 1000
      ? {
          position: "absolute",
          bottom: 95,
          right: 45,
          zIndex: "1000",
          width: "fit-content",
          display: "flex",
        }
      : {
          position: "fixed",
          bottom: 25,
          left: 10,
          zIndex: "1000",
          width: "fit-content",
          display: "flex",
        };

  styles.innerContainer = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #b2b2b2",
    borderRadius: 9,
  };

  styles.prevButton = {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    padding: "4px 8px",
    backgroundColor: "#212735",
    cursor: "pointer",
  };

  styles.nextButton = {
    backgroundColor: "#006b95",
    color: "#fff",
    padding: "5px 10px 5px 15px",
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };

  styles.arrowRightIcon = { transform: "translateY(1px)" };

  styles.arrowLeftIcon = { color: "#fff" };

  const handleNext = async () => {
    await moodle.navigateNext();
  };

  const handlePrev = async () => {
    await moodle.navigatePrev();
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.prevButton} onClick={handlePrev}>
          <KeyboardArrowLeftIcon sx={styles.arrowLeftIcon} />
        </div>
        <div style={styles.nextButton} onClick={handleNext}>
          {window.screen.width > 1000 ? (
            <p className="m-0">{moodle.nextResourceName}</p>
          ) : (
            <p className="m-0">
              {moodle.nextResourceName === "Fin del curso"
                ? "Fin del curso"
                : "Siguiente"}
            </p>
          )}
          <KeyboardArrowRightIcon sx={styles.arrowRightIcon} />
        </div>
      </div>
    </div>
  );
};
