import React from "react";
import { setSenceSession } from "../../../utils/cookies";
import { CustomSpinner } from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { useMoodle } from "../../../contexts/moodle";

export const SenceLogin = () => {
  const navigate = useNavigate();
  const moodle = useMoodle();
  React.useEffect(() => {
    console.log("window.location.search", window.location.search);
    if (window.location.search) {
      let session = setSenceSession(true);
      if (session) {
        let course = localStorage.getItem("current_course");
        localStorage.setItem("last_login_sence", new Date());
        localStorage.setItem("senceSession", true);
        if (course) {
          // window.location.replace(`/detalle-curso/${course}`);
          navigate(`/detalle-curso/${course}`);
        } else {
          moodle.getCourses().then((res) => {
            console.log(session.CodSence, res);
            let sence_course = res.filter(
              (c) => c.idnumber.split("sence-")[1] === session.CodSence
            );
            console.log(sence_course[0].id);
            navigate(`/detalle-curso/${sence_course[0].id}`);
          });
          // navigate("/mis-cursos");
        }
      }
    }
    //eslint-disable-next-line
  }, []);

  return <CustomSpinner />;
};
