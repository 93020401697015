import React from "react";
import { TipddyFooter } from "../TipddyFooter";
import "./styles.css";

export const Footer = () => {
  return (
    <div className="footer-container text-center">
      <div className="row-footer">
        <div className="">
          <a
            className="sitio-web"
            href="http://www.asec.cl"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.asec.cl
          </a>
        </div>
        {/* <div className="rrss-container">
          <p className="tit-footer">Nuestras Redes Sociales</p>
        </div> */}
        <div className="d-flex justify-content-center">
          <ul className="links-rrss-footer">
            <li>
              <a
                href="https://www.linkedin.com/company/asec-capacitacion/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../assets/images/linkedin.png")}
                  alt="icono linked in"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/aseccapacitacion/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../assets/images/facebook.png")}
                  alt="icono facebook"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/asec_capacitacion/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../assets/images/instagram.png")}
                  alt="icono instagram"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* <TipddyFooter /> */}
    </div>
  );
};
