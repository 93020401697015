import "./index.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { ResourceLabelComponent } from "../ResourceLabelComponent";
import { useEffect, useRef, useState } from "react";

const LABEL = "label";

export const CourseContentModuleRowComponent = ({
  module,
  moodle,
  idx,
  container,
}) => {
  const rowContainer = useRef(null);
  const [newmodules, setnewmodules] = useState(null);
  const [isCurrentModule, setCurrentModule] = useState(null);
  const [spreed, setSpreed] = useState(false);

  useEffect(() => {
    let filtered = module.modules.filter((res) => res.modname !== LABEL);
    setnewmodules(filtered);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let isCurrent = module.id === moodle.currentModuleId;
    setCurrentModule(isCurrent);
    isCurrent ? setSpreed(true) : setSpreed(false);

    // eslint-disable-next-line
  }, [moodle.currentModuleId]);

  const handleSpreed = () => {
    setCurrentModule(true);
    module.modules.length > 0 && setSpreed(!spreed);
  };

  return (
    <div
      ref={rowContainer}
      className="wrapper-row"
      style={{
        backgroundColor: isCurrentModule ? "#212735" : "#15171f",
        border: isCurrentModule ? "1px solid #454255" : "unset",
        opacity: isCurrentModule ? 1 : 0.4,
      }}
    >
      <div className="row-container" onClick={() => handleSpreed(module)}>
        <div className="circle-btn-container mx-2">
          <div
            className="circle-btn"
            style={{ backgroundColor: isCurrentModule ? "#006b95" : "unset" }}
          >
            <p style={{ color: isCurrentModule ? "#b2b2b2" : "#454255" }}>
              {idx + 1}
            </p>
          </div>
        </div>

        <div className="title-container" style={{ width: "100%" }}>
          <div
            className="title-btn"
            style={{
              fontSize: "13px",
              color: "#fff",
            }}
          >
            {module.name}
          </div>
          <div>
            {module.hasProgress && (
              <ProgressBar progress={module.rawProgress} />
            )}
          </div>
        </div>
        {newmodules && newmodules.length > 0 && (
          <div>
            {spreed ? (
              <KeyboardArrowDownIcon sx={{ color: "#b2b2b2" }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ color: "#b2b2b2" }} />
            )}
          </div>
        )}
      </div>
      <div
        style={{
          paddingLeft: "45px",
        }}
      >
        {spreed &&
          newmodules &&
          newmodules.map((resource) => {
            return (
              <ResourceLabelComponent
                container={container}
                module={module}
                resource={resource}
                key={resource.id}
                moodle={moodle}
                idx={idx}
              />
            );
          })}
      </div>
    </div>
  );
};

const ProgressBar = ({ progress }) => {
  let width = "100";
  let progressWidth = (progress * width) / 100;

  return (
    <div
      className="title-container"
      style={{ transform: "translateY(10px)", width: "100%" }}
    >
      <div
        style={{
          backgroundColor: "#b2b2b2",
          width: `${width}%`,
          height: 5,
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            backgroundColor: "#cc9114",
            width: `${progressWidth}%`,
            height: 5,
            borderRadius: "5px",
          }}
        ></div>
      </div>
      <span
        style={{
          color: "#b2b2b2",
          fontSize: "10px",
          marginTop: "5px",
        }}
      >
        {progress}% completado
      </span>
    </div>
  );
};
