import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CustomSpinner } from "../../../components/Loader";
import { SNACKTYPES, useSnack } from "../../../contexts/snack";
import error from "./data.json";

export const SenceErrorLogin = () => {
  const [, handleSnack] = useSnack();

  const navigate = useNavigate();

  useEffect(() => {
    let URL_error = window.location.href.slice(-3);
    let error_description = "";
    for (let i = 0; i < error.error.length - 1; i++) {
      if (URL_error === error.error[i].value) {
        error_description = error.error[i].description;
      }
    }
    handleSnack({
      type: SNACKTYPES.error,
      payload: {
        message: `${error_description}`,
      },
    });
    setTimeout(() => {
      navigate("/mis-cursos");
    }, 10000);
    // eslint-disable-next-line
  }, []);

  return <CustomSpinner />;
};

export const SenceErrorLogout = () => {};
