import React, { useEffect } from "react";
import "./styles.css";
import { Layout } from "../../components/Layout";
import { CarouselBanner } from "../../components/CarouselBanner";
// import bannerImage1 from "../../assets/images/banner-mis-cursos-1.jpg";
// import bannerImage2 from "../../assets/images/banner-mis-cursos-2.jpg";
// import bannerImage3 from "../../assets/images/banner-mis-cursos-3.jpg";
// import bannerImage4 from "../../assets/images/banner-mis-cursos-4.jpg";
import { CoursePreviewRow } from "../../components/CoursePreviewRow";
import { useMoodle } from "../../contexts/moodle";
import { useAuth } from "../../contexts/auth";
import { useMallas } from "../../contexts/mallas";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { listAds } from "../../services/tipddy/dashboard";
import { CLIENT_ID } from "../../utils/clients";

export const MisCursos = () => {
  const auth = useAuth();
  const moodle = useMoodle();
  const malla = useMallas();
  const [loading, setLoading] = React.useState(true);
  const [loadingAds, setLoadingAds] = React.useState(true);
  const [ads, setAds] = React.useState([]);
  // const [erapCompleted, setErapCompleted] = React.useState(false);
  const [erapCompleted, setErapCompleted] = React.useState([
    true,
    true,
    true,
    true,
  ]);

  // let array = [bannerImage1, bannerImage2, bannerImage3, bannerImage4];

  useEffect(() => {
    !moodle.courses && moodle.getCourses();
    !moodle.categories && moodle.getCategories();
    moodle.courses && malla.fetchInitialMalla();

    // eslint-disable-next-line
  }, [moodle]);

  useEffect(() => {
    if (malla.malla?.malla) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

    // eslint-disable-next-line
  }, [moodle.courses, malla]);

  useEffect(() => {
    // let client = auth.user.institution.toLowerCase();
    let client = "carozzi";
    if (CLIENT_ID[`${client}`]) {
      listAds(CLIENT_ID[`${client}`]).then((res) => {
        res.sort((p1, p2) =>
          p1.type_ads > p2.type_ads ? 1 : p1.type_ads < p2.type_ads ? -1 : 0
        );
        /*         setLoadingAds(false); */
        console.log({ res });
        setAds(res);
      });
    }
    // eslint-disable-next-line
  }, []);

  const getAllCourseImages = (courses_array) => {
    // console.log("courses array: ", courses_array);
    let new_array = [];
    let img_url_array = [];
    courses_array.forEach((element) => {
      new_array.push(element.course_id);
    });

    for (let i = 0; i < new_array.length; i++) {
      for (let j = 0; j < moodle.courses.length; j++) {
        if (new_array[i] === `${moodle.courses[j].id}`) {
          if (moodle.courses[j].overviewfiles.length > 0) {
            img_url_array.push(moodle.getCourseImg(moodle.courses[j]));
          }
        }
      }
    }
    // console.log("img url: ", img_url_array);
    return img_url_array;
  };

  return (
    <Layout helpPopUp={"listcourses"}>
      <div className="mis-cursos-container">
        {!ads && <SkeletonBanner />}

        <div className="carousel-banner">
          <CarouselBanner array={ads} />
        </div>

        {loading &&
          [1, 2, 3, 4].map((i) => {
            return <CardCoursesRowSkeleton key={i} />;
          })}

        {!loading &&
          moodle.categories &&
          malla.malla?.malla.map((element, idx) => (
            <CoursePreviewRow
              key={idx}
              loading={loading}
              title={element.category_name}
              sub_title={moodle.categories[idx].description}
              img={getAllCourseImages(element.courses)}
              courses={element.courses}
              erapCompleted={erapCompleted[idx]}
            />
          ))}
      </div>
    </Layout>
  );
};

const SkeletonBanner = () => {
  return (
    <Skeleton
      baseColor="#cccccc"
      style={{
        opacity: 0.5,
        width: "100%",
        height: "25rem",
      }}
    />
  );
};

export const CardCoursesRowSkeleton = () => {
  return (
    <div className="px-3 py-4">
      <Skeleton
        baseColor="#cccccc"
        style={{
          opacity: 0.5,
          width: 420,
          height: "2rem",
        }}
      />
      <Skeleton
        baseColor="#cccccc"
        style={{
          opacity: 0.5,
          width: "52%",
          height: "1.2rem",
          marginTop: "1rem",
        }}
      />
      <div style={{ display: "flex", gap: 30 }}>
        {[1, 2, 3, 4].map((i) => {
          return (
            <Skeleton
              key={i}
              baseColor="#cccccc"
              style={{
                opacity: 0.5,
                width: "450px",
                borderRadius: "5px",
                height: "250px",
                marginTop: "1rem",
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
