import React from "react";
import "./styles.css";
import { Layout } from "../../components/Layout";
import grafica from "../../assets/images/merca.jpg";
import grafica_cell from "../../assets/images/banner-pagina-cel.jpg";

export const QueEsMerkademia = () => {
  return (
    <Layout>
      <div className="perfil-box">
        <div className="perfil-container">
          <p className="tit-perfil">¿Qué es Merkademia?</p>
          <div className="row justify-content-center">
            <img
              className="imagen-que-es-merkademia"
              src={window.screen.width > 1000 ? grafica : grafica_cell}
              alt="foto merkademia"
            />
            <p className="texto-perfil">
              Merkademia es la primera academia de mercaderistas en Chile, un
              espacio de capacitación y perfeccionamiento que se basa en un Plan
              Formativo bajo el modelo de Competencias Laborales. Su objetivo es
              alinear la demanda del sector productivo, permitiendo a los
              participantes adquirir y desarrollar diversas competencias que les
              faciliten su inserción y permanencia en el mercado laboral.
            </p>
            <p className="texto-perfil">
              Entre los atributos que destacan de Merkademia, se encuentra un
              modelo con diseño flexible que se adapta a tus necesidades y
              horarios, una metodología activa y pertinente al contexto del
              participante que contribuye a la formación integral en el puesto
              de trabajo del mercaderista, así como la posibilidad de
              reconocimiento de aprendizajes previos mediante evaluación
              diagnóstica, aplicable para el primer nivel de cursos de
              Merkademia.
            </p>
            <p className="texto-perfil">
              Además, Merkademia cuenta con una plataforma de gestión integrada
              de aprendizaje modalidad e-learning, intuitiva y personalizable
              para diversos dispositivos, lo que te permite acceder a los cursos
              en cualquier momento y lugar.
            </p>
            <p className="texto-perfil">
              No pierdas la oportunidad de formarte en Merkademia y adquirir las
              competencias necesarias para destacar en el mercado laboral.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};
