import { Backdrop } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Footer } from "../Footer";
import { Navbar } from "../Navbar";
import { BackdropNavbar } from "../Navbar/backdrop";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export const Layout = ({ children, footer = true, helpPopUp = null }) => {
  const [help, setHelp] = useState(false);
  const nav = useRef(null);

  function handleHelp() {
    setHelp(!help);
  }

  useEffect(() => {}, [nav]);

  return (
    <div>
      <Navbar handleHelp={handleHelp} nav={nav} />
      <div
        style={{
          marginTop: nav?.current?.offsetHeight
            ? `${nav.current.offsetHeight}px`
            : "89px",
        }}
      >
        {children}
      </div>

      {footer && <Footer />}
      <HelpPopUp
        page={helpPopUp}
        open={help}
        setOpen={setHelp}
        handleHelp={handleHelp}
      />
    </div>
  );
};

//** AVANZAR AQUI */
const HelpPopUp = ({ page = "home", open, setOpen, handleHelp }) => {
  React.useEffect(() => {
    if (page === "listcourses") {
      Fancybox.bind('[data-fancybox="help-listcourses"]', {
        autoStart: true,
      });

      open &&
        document.querySelector('[data-fancybox="help-listcourses"]').click();
      setOpen(false);
    }
    //eslint-disable-next-line
  }, [open]);

  if (page !== "listcourses") {
    return (
      <Backdrop
        /* sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} */
        sx={{ color: "#fff", zIndex: 1003 }}
        open={open}
      >
        <BackdropNavbar handleHelp={handleHelp} />
      </Backdrop>
    );
  }

  return (
    <Backdrop
      /* sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} */
      sx={{ color: "#fff", zIndex: 1003 }}
      open={open}
    >
      <BackdropNavbar handleHelp={handleHelp} />
      {page.toLowerCase() === "listcourses" && (
        <a
          data-fancybox="help-listcourses"
          href="https://vimeo.com/827725924?share=copy"
          style={{ visibility: "hidden" }}
        >
          Video
        </a>
      )}
    </Backdrop>
  );
};
